import React from 'react'

// ReSharper disable once InconsistentNaming
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store, history } from 'store'
import AppSettings from 'providers/AppSettings'
import AssetLoader from 'providers/AssetLoader'
import SignalR from 'providers/SignalR'
import Authentication from 'providers/Authentication'
import Initialiser from 'providers/Initialiser'
import { HistoryRouter as Router } from 'redux-first-history/rr6'

import App from './App'

import { PrimeReactProvider } from 'primereact/api'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')

ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <Router basename={baseUrl} history={history}>
                    <PrimeReactProvider>
                        <Initialiser id="initialiser">
                            <AppSettings id="appSettings_Loader">
                                <Authentication id="authentication">
                                    <AssetLoader id="asset_Loader">
                                        <SignalR id="signalR_Connector">
                                            <App id="app"/>
                                        </SignalR>
                                    </AssetLoader>
                                </Authentication>
                            </ AppSettings>
                        </Initialiser>
                    </PrimeReactProvider>
                </Router>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    )
