import { checkDuplicateIda } from 'components/Body/data-entry/AudioVisual/validations'
        
export const validateEpisodeListDetails = async (toast, episodeList, programmeUrl) => {
    // Perform validation checks
    // Return true if valid, false otherwise
    let isValid = true
    const idaCodes = new Set()

    for (const episodeData of Object.values(episodeList)) {    
        // Program Main title should not be empty
        if (!episodeData.episodeTitle || episodeData.episodeTitle.trim() === "") {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode Title is Mandatory' })
            isValid = false
        }
        if (!episodeData.episodeNumber && episodeData.episodeNumber < 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode Number is Mandatory' })
            isValid = false
        }
        if (!episodeData.episodeSeries && episodeData.episodeSeries < 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Series Number is Mandatory' })
            isValid = false
        }
        if (episodeData.episodeDuration === "" || episodeData.episodeDuration < 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Duration is Mandatory' })
            isValid = false
        }
        if (episodeData.episodeYear === "" || episodeData.episodeYear <= 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Year Produced is Mandatory' })
            isValid = false
        }
        // Master IDA ID should be numeric
        if (episodeData.episodeIda && isNaN(episodeData.episodeIda)) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode IDA should be numeric' })
            isValid = false
        }
        // Check for duplicate IDA
        if (episodeData.episodeIda) {
            if (idaCodes.has(episodeData.episodeIda) && episodeData.id !== episodeList[episodeData.episodeId].episodeId) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'IDA already exists' })
                isValid = false
            } else {
                idaCodes.add(episodeData.episodeIda)
            }
            // Check for duplicate IDA in the database
            const duplicateIdaMessage = await checkDuplicateIda(episodeData.episodeIda, programmeUrl, false, episodeData.programmeId, episodeData.episodeId)
            if (duplicateIdaMessage) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: duplicateIdaMessage })
                isValid = false
            }
        }
    }
    return isValid
}