import { useDispatch, useSelector } from 'react-redux'
import { setAuthenticatedAction, setErrorAction } from './store/actions'
import { selectError } from './store/selectors'
import { selectAuthApiHost } from '../../globalSelectors'
import { history } from '../../store'

// ReSharper disable once InconsistentNaming
const Authentication = (props) => {
    /* Dispatch */
    const dispatch = useDispatch()
    const setAuthenticated = (status) => dispatch(setAuthenticatedAction(status))
    const setError = (err) => dispatch(setErrorAction(err))

    /* Selectors*/
    const error = useSelector(selectError)
    const host = useSelector(selectAuthApiHost)
    const authApiUrl = `${host}`

    const params = new URLSearchParams(window.location.search)
    const secureKey = params.get('secureKey')
    const authToken = sessionStorage.getItem('authToken')

    const fetchToken = async (key) => {
        try {
            const response = await fetch(`${authApiUrl}/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ SecureKey: key })
            })

            if (response.ok) {
                const data = await response.json()
                history.push('/')
                sessionStorage.setItem('authToken', data.accessToken)
                sessionStorage.setItem('refreshToken', data.refreshToken)
                const tokenData = JSON.parse(data.accessToken)
                setAuthenticated({
                    isAuthenticated: true,
                    departments: tokenData.Departments,
                    scopes: tokenData.Scopes,
                    userName: tokenData.UserName

                })
            } else {
                setError({
                    message: 'Error when logging in.',
                    error: `Error refreshing access token: ${response.statusText}`
                })
                setAuthenticated({
                    isAuthenticated: false,
                    departments: [],
                    scopes: [],
                    userName: ''
                })
                return null
            }
        } catch (err) {
            setError({
                message: 'Error when logging in.',
                error: err
            })
            setAuthenticated({
                isAuthenticated: false,
                departments: [],
                scopes: [],
                userName: ''
            })
        }
        return null
    }

    const refreshTheToken = async () => {
        try {
            const sessionRefreshToken = sessionStorage.getItem('refreshToken')

            if (!sessionRefreshToken) {
                setError({
                    message: 'No refresh token found.',
                    error: `Error refreshing access token: refreshToken not found in session storage`
                })
                return
            }

            const response = await fetch(`${authApiUrl}/refresh`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(sessionRefreshToken)
            })

            if (response.ok) {
                const data = await response.json()
                const { accessToken, refreshToken } = data
                sessionStorage.setItem('authToken', accessToken)
                sessionStorage.setItem('refreshToken', refreshToken)
                const tokenData = JSON.parse(accessToken)
                setAuthenticated({
                    isAuthenticated: true,
                    departments: tokenData.Departments,
                    scopes: tokenData.Scopes,
                    userName: tokenData.UserName
                })
            } else if (response.status === 401) {
                sessionStorage.removeItem('authToken');
                sessionStorage.removeItem('refreshToken');
                window.location.replace(authApiUrl);
                setError({
                    message: 'Error when logging in.',
                    error: 'Error refreshing access token: Refresh token expired or invalid.'
                })
                setAuthenticated({
                    isAuthenticated: false,
                    departments: [],
                    scopes: [],
                    userName: ''
                })
            } else {
                setError({
                    message: 'Error when logging in.',
                    error: `Error refreshing access token: ${response.statusText}`
                })
                setAuthenticated({
                    isAuthenticated: false,
                    departments: [],
                    scopes: [],
                    userName: ''
                })
            }

            setTimeout(refreshTheToken, 1800 * 1000);
        } catch (err) {
            setError({
                message: 'Error when logging in.',
                error: err
            })
            setAuthenticated({
                isAuthenticated: false,
                departments: [],
                scopes: [],
                userName: ''
            })
        }
    }

    if (!secureKey && !authToken) {
        window.location.replace(authApiUrl)
        return undefined
    } else if (!authToken && secureKey.length > 0) {
        fetchToken(secureKey)
    } else {
        const tokenData = JSON.parse(authToken)
        const expirationDate = new Date(tokenData.Expires)
        const currentDate = new Date()

        if (expirationDate < currentDate) {
            throw new Error('Token has expired.')
        } else {
            setAuthenticated({
                isAuthenticated: true,
                departments: tokenData.Departments,
                scopes: tokenData.Scopes,
                userName: tokenData.UserName
            })
        }
    }
    setTimeout(refreshTheToken, 1800 * 1000);

    if (error) {
        return (
            <div id={props.id}>
                <p className={'error'}>{JSON.stringify(error)}</p>
            </div>
        )
    }

    return (
        <div id={props.id}>
            {props.children}
        </div>
    )
}
export default Authentication;
