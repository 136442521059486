import { authFetch } from 'api/auth/authFetch'
import { removeDuplicatesTitles, removeEmptyRowsFromTitle } from 'components/Body/data-entry/AudioVisual/programmeDetailsUtils' 
import { setAreEpisodeLanguagesInvalid, setAreEpisodeTitlesInvalid, setEpisodeTitles } from 'components/Body/data-entry/AudioVisual/store/actions'


export const validateProgrammeChanges = async (toast, dispatch, resultsDataForEdit, primaryTitle, programmeTypeId, formatId, primaryCountry, episodeData, episodePrimaryTitle, episodeValidationRequired, programmeGuid, programmeUrl) => {
    let isValid = true;

    if (resultsDataForEdit) {
        // Program Main title should not be empty
        if (!primaryTitle || !primaryTitle.title.trim()) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Primary Title is required' })
            isValid = false
        }
        // Master IDA ID should be numeric
        if (resultsDataForEdit.idAid && isNaN(resultsDataForEdit.idAid)) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Master IDA should be numeric' })
            isValid = false
        }
        if (resultsDataForEdit.idAid && String(resultsDataForEdit.idAid).length !== 12) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Master IDA must be 12 characters long' })
            isValid = false           
        }
        // Check for duplicate IDA
        if (resultsDataForEdit.idAid) {
            const duplicateIdaMessage = await checkDuplicateIda(resultsDataForEdit.idAid, programmeUrl, true,  programmeGuid,null)
            if (duplicateIdaMessage) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: duplicateIdaMessage })
                isValid = false
            }
        }
        // Programme Type should be selected
        if (!programmeTypeId) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Programme Type is required' })
            isValid = false
        }
        // Format should be selected
        if (!formatId) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Format is required' })
            isValid = false
        }
        else {
            if (formatId === 1 || formatId === 5) {
                if (!resultsDataForEdit.animeOrLive) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Anime/Live is required for Film Type' })
                    isValid = false
                }
            }
        }
        // Language should be selected
        if (!resultsDataForEdit.languageId) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Language is required' })
            isValid = false
        }
        // Primary Country should be selected
        if (!primaryCountry || !primaryCountry.countryId) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Primary Country is required' })
            isValid = false
        }        

        if (episodeValidationRequired) {
            isValid = isValid && validateEpisodeDetails(toast, dispatch, episodeData, episodePrimaryTitle, programmeGuid, programmeUrl)
        }       

    }

    return isValid
}

export const validateEpisodeDetails = async (toast, dispatch, episodeData, episodePrimaryTitle, programmeGuid,programmeUrl) => {
   
    let isValid = true    

    if (episodeData) {

        if (!episodePrimaryTitle || episodePrimaryTitle.title.trim() === "") {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode Title is Mandatory' })
            isValid = false
        }

        if (episodeData.number === "" || episodeData.number < 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode Number is Mandatory' })
            isValid = false
        }

        if (episodeData.series === "" || episodeData.series < 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Series Number is Mandatory' })
            isValid = false
        }

        if (episodeData.durationMinutes === "" || episodeData.durationMinutes < 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Duration is Mandatory' })
            isValid = false
        }

        if (episodeData.yearOfProduction === "" || episodeData.yearOfProduction <= 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Year Produced is Mandatory' })
            isValid = false
        }  
        // Master IDA ID should be numeric
        if (episodeData.idaCode && isNaN(episodeData.idaCode)) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode IDA should be numeric' })
            isValid = false
        }
        //IDA code must be of length 12 characters
        if (episodeData.idaCode && String(episodeData.idaCode).length !== 12) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode IDA must be 12 characters long' })
            isValid = false
        }
        // Check for duplicate IDA
        if (episodeData.idaCode) {
            const duplicateIdaMessage = await checkDuplicateIda(episodeData.idaCode, programmeUrl, false, programmeGuid, episodeData.id)
            if (duplicateIdaMessage) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: duplicateIdaMessage })
                isValid = false
            }
        }

        // Perform validation for titles
        if (episodeData.titles) {
            isValid = isValid && validateTitleChanges(toast, dispatch, episodeData.titles)
        }
    }
    else {
        isValid = false
    }

    return isValid
}

export const validateEpisodeListDetails = async (toast, programmeData, episodeList,programmeUrl) => {
    // Perform validation checks
    // Return true if valid, false otherwise
    let isValid = true
    const idaCodes = new Set()

    for (const episodeData of Object.values(episodeList)) {
        // Program Main title should not be empty
        if (!episodeData.title || episodeData.title.trim() === "") {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode Title is Mandatory' })
            isValid = false
        }

        if (episodeData.number === null || episodeData.number < 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode Number is Mandatory' })
            isValid = false            
        }

        if (episodeData.series === null || episodeData.series < 0) {           
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Series Number is Mandatory' })
            isValid = false   
        }
        if (episodeData.durationMinutes === null || episodeData.durationMinutes < 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Duration is Mandatory' })
            isValid = false
        }
        if (episodeData.yearOfProduction === null || episodeData.yearOfProduction <= 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Year Produced is Mandatory' })
            isValid = false
        }
        // Master IDA ID should be numeric
        if (episodeData.idaCode && isNaN(episodeData.idaCode)) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode IDA should be numeric' })
            isValid = false
        }
        // Check for duplicate IDA
        if (episodeData.idaCode) {
            if (idaCodes.has(episodeData.idaCode) && episodeData.id !== episodeList[episodeData.id].id) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'IDA already exists' })
                isValid = false
            } else {
                idaCodes.add(episodeData.idaCode)
            }
            if (episodeData.idaCode && String(episodeData.idaCode).length !== 12) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'IDA must be 12 characters long' })
                isValid = false
            }
            // Check for duplicate IDA in the database
            const duplicateIdaMessage = await checkDuplicateIda(episodeData.idaCode, programmeUrl, false, programmeData.id, episodeData.id)
            if (duplicateIdaMessage) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: duplicateIdaMessage })
                isValid = false
            }
        }
    }
    return isValid
}

export const validateTitleChanges = (toast, dispatch, titles) => {    
    let isValid = true
    titles = titles && titles.length > 0 && titles?.filter(title => title.titleTypeId !== 1)
    const emptyRowsRemoved = removeEmptyRowsFromTitle(titles)
    dispatch(setEpisodeTitles(emptyRowsRemoved))

    const uniqueTitles = removeDuplicatesTitles(emptyRowsRemoved)
    dispatch(setEpisodeTitles(uniqueTitles))

    // Check if all titles have title filled
    const invalidTitles = uniqueTitles.map((title, index) => {
        if (!title.title) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Title at row ${index + 1} must have both language and title filled` })
            return true
        }
        return false
    })
    dispatch(setAreEpisodeTitlesInvalid(invalidTitles))
    if (invalidTitles.some(invalid => invalid)) {
        isValid = false
    }
    // Check if all titles have both language and title filled
    const invalidLanguages = uniqueTitles.map((title, index) => {
        if (!title.languageId) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Language at row ${index+1} must have both language and title filled` })
            return true
        }
        return false
    })
    dispatch(setAreEpisodeLanguagesInvalid(invalidLanguages))
    if (invalidLanguages.some(invalid => invalid)) {
        isValid = false
    }


    // If all validations pass
    return isValid
}

// returns a bool indicating whether the new format is valid
export const validateChangeProgrammeFormat = (newFormatId, programmeData) => {
    
    // We get programme data just to keep the interface clean,
    // but we only care about the episode count

    switch (newFormatId.sapCode) {
        case "FF":
        case "TF":
            // The rule is we do not allow when multiple episodes
            return (Object.keys(programmeData.episodes).length <= 1)
        default:
            return true
    }

}

export const checkDuplicateIda = async (idaCode, programmeUrl, isProgramme, programmeId, episodeId) => {   
    let endpoint = `${programmeUrl}/${programmeId}/CheckDuplicateIda?idaNumber=${idaCode}`
    if (!isProgramme) {
        endpoint = `${programmeUrl}/${programmeId}/Episodes/${episodeId}/CheckDuplicateIda?idaNumber=${idaCode}`
    }    
    try {
        var response = await authFetch(endpoint)
        var isDuplicate = await response.json()

        if (isDuplicate === true) {
            return 'IDA already exists'
        }
    } catch (error) {
        console.error('Error:', error)
    }
    return undefined
}

