import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { componentsReducer, audioVisualBlacklist, searchAllResultsBlacklist, globalSearchBarBlacklist, secondaryNavigationBlacklist, searchResultsPanelsBlacklist, audioVisualLandingBlacklist, searchActionsBlacklist, audioVisualActionsBlacklist, searchResultsPreviewPanelsBlacklist } from 'components/store'
import { appSettingsReducer } from 'providers/AppSettings/store'
import { reducer as initialiseReducer } from 'providers/Initialiser'
import { assetReducer } from 'providers/AssetLoader/store'
import { signalRReducer } from 'providers/SignalR/store'
import { authReducer } from 'providers/Authentication/store'
import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory } from 'history'
import { createStateSyncMiddleware, initStateWithPrevTab, initMessageListener } from 'redux-state-sync'

if (componentsReducer === undefined) {
    throw new Error('componentsReducer is undefined')
}
if (appSettingsReducer === undefined) {
    throw new Error('appSettingsReducer is undefined')
}
if (assetReducer === undefined) {
    throw new Error('assetReducer is undefined');
}
if (signalRReducer === undefined) {
    throw new Error('signalRReducer is undefined')
}
if (authReducer === undefined) {
    throw new Error('authReducer is undefined')
}

// Create Redux history context
const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({
    history: createBrowserHistory()
})

const rootReducer = combineReducers({
    router: routerReducer,
    components: componentsReducer,
    appSettings: appSettingsReducer,
    assets: assetReducer,
    signalR: signalRReducer,
    authentication: authReducer,
    hostDns: initialiseReducer
    // add other reducers 
});

const syncConfig = {
    blacklist: [
        ...secondaryNavigationBlacklist,
        ...searchResultsPanelsBlacklist,
        ...audioVisualBlacklist,
        ...searchAllResultsBlacklist,
        ...globalSearchBarBlacklist,
        ...audioVisualLandingBlacklist,
        ...searchActionsBlacklist,
        ...audioVisualActionsBlacklist,
        ...searchResultsPreviewPanelsBlacklist
    ] // Add actions that should not be synchronized
 
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(routerMiddleware)
        .concat(createStateSyncMiddleware(syncConfig)),
    devTools: true,
    preloadedState: {},
    enhancers: []
})

initStateWithPrevTab(store);
initMessageListener(store);

export const history = createReduxHistory(store)
