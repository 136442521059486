import React, { useRef, useState, useEffect } from 'react'
import StaticCommonGrid from 'components/common/StaticCommonGrid'
import { useDispatch, useSelector } from 'react-redux'
import { selectEpisodeDraftHasTransmissions,selectAudiovisualSort,selectEpisodeResultPresentation, selectEpisodeResultOriginal, selectProgrammeGuid } from 'components/Body/data-entry/AudioVisual/store/selectors'
import { setAudiovisualSortAction, setEpisodeDraftResultsTransmissionsAction, clearEpisodeDraftResultsTransmissionsAction } from 'components/Body/data-entry/AudioVisual/store/actions'
import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { selectAllAssets } from 'providers/AssetLoader/store/selectors'
import { selectAvApiHost } from 'globalSelectors' 
import * as Xlsx from 'xlsx'
import { ExportFormat } from 'modules/Constants'

export function Transmissions(props) {
    const dispatch = useDispatch()
    const setAcceptedTransmission = (transmission) => dispatch(setEpisodeDraftResultsTransmissionsAction(transmission))
    const clearTransmission = (transmission) => dispatch(clearEpisodeDraftResultsTransmissionsAction(transmission))
    const toast = React.useRef(null)
    const childRef = useRef()
    const episodeDataOriginal = useSelector(selectEpisodeResultOriginal)
    const episodeData = useSelector(selectEpisodeResultPresentation)
    const programmeId = useSelector(selectProgrammeGuid)
    const { channels } = useSelector(selectAllAssets)
    const avHost = useSelector(selectAvApiHost)
    const sort = useSelector(selectAudiovisualSort)
    const hasTransmissionsModified = useSelector(selectEpisodeDraftHasTransmissions)
    const allTransmissions = episodeData && episodeData.transmissions ? episodeData.transmissions : []
    const transmissionsOriginal = episodeDataOriginal && episodeDataOriginal.transmissions ? episodeDataOriginal.transmissions : []
    const isFilm = props.isFilm
    const excelDownloadName = 'Transmissions.xlsx'
    const excelDownloadPath = `${avHost}/Programme/${programmeId}/Episodes/${episodeData.id}/TransmissionsExcelExport`
    const isTransmissionsOriginalEmpty = !transmissionsOriginal || Object.keys(transmissionsOriginal).length === 0


    const [scrollHeight, setScrollHeight] = useState(calculateScrollHeight())
  
    //Andy L approves this useEffect
    useEffect(() => {
        const handleResize = () => {
            setScrollHeight(calculateScrollHeight())
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    //TODO: We should not be changing styles in JS. This should be done in CSS. The staticcommonGrid doesnot allow for this to be done in CSS
    function calculateScrollHeight() {
        const adjustedHeight = window.innerHeight / window.devicePixelRatio;
        let height = adjustedHeight * 0.2
        if (!isFilm) {
            height = height - 30
        }
        return `${height}px`
    }   

  
    const transformTransmissions = (transmissions) => {
        const transformedTransmissions = Object.values(transmissions).map((d) => ({
            ...d,
            date: new Date(d.date),
            time: new Date(d.date),
            channel: channels.find(c => c.id === d.channelId)
        }))
        return transformedTransmissions
    }

    const transformTransmissionsForExcel = (transmissions) => {
        const transformedTransmissions = Object.values(transmissions).map((d) => {
            const dateObj = new Date(d.date)
            const date = dateObj.toLocaleDateString('en-GB') // Format date as dd/mm/yyyy
            const time = dateObj.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) // Format time as hh:mm
            const channel = channels.find(c => c.id === d.channelId)?.name || ''

            return {
                ...d,
                date,
                time,
                channel
            }
        })
        return transformedTransmissions
    }


    const handleAddTransmission = () => {
        if (childRef.current) {
            childRef.current.addNewRow()
        }
    }

    const handleDeleteTransmission = (rowData, rowIndex) => {
        clearTransmission(rowData.id)
        childRef.current.deleteRow(rowIndex)
    }

    const validateData = (rowData) => {
        const errors = {}
        if (!rowData.date || rowData.date === '') errors.date = 'Date is mandatory'
        if (!rowData.time || rowData.time === '') errors.time = 'Time is mandatory'
        if (!rowData.channel || rowData.channel === '') errors.channel = 'Channel is mandatory'
        return errors
    }

    const handleRowEditSave = (rowData) => {
        const { channel, date, time, ...rest } = rowData
        const updatedRowData = {
            ...rest,
            date: new Date(date.setHours(time.getHours(), time.getMinutes())).toISOString().slice(0, 19),
            channelId: channel.id
        };

        (transmissionsOriginal && JSON.stringify(transmissionsOriginal) === JSON.stringify(updatedRowData)) ? clearTransmission(rowData.id) : setAcceptedTransmission({ [rowData.id]: updatedRowData })
    }

    const applySort = (data) => {
        if (!sort || sort.length === 0) {
            return data
        }

        const dataArray = Array.isArray(data) ? data : Object.values(data)

        return dataArray.sort((a, b) => {
            for (let i = 0; i < sort.length; i++) {
                const { field, order } = sort[i]
                let result = 0
                if (field === 'date') {                    
                    const dateA = new Date(a.date)
                    const dateB = new Date(b.date)
                    result = dateA - dateB
                }           
                if (result !== 0) {
                    return order * result
                }
            }
            return 0
        })
    }

    const handleExport = (format) => {
        const sortedData = applySort(allTransmissions);
        const dataToExport = transformTransmissionsForExcel(sortedData).map(({ id, channelId, ...rest }) => rest)

        // Capitalize the first letter of each column name
        const capitalizedDataToExport = dataToExport.map(item =>
            Object.fromEntries(Object.entries(item).map(([key, value]) => [key.charAt(0).toUpperCase() + key.slice(1), value]))
        )

        if (format === ExportFormat.Excel) {
            const worksheet = Xlsx.utils.json_to_sheet(capitalizedDataToExport)
            const workbook = Xlsx.utils.book_new()
            Xlsx.utils.book_append_sheet(workbook, worksheet, 'Transmissions')
            Xlsx.writeFile(workbook, excelDownloadName)
        } else if (format === ExportFormat.Csv) {
            const worksheet = Xlsx.utils.json_to_sheet(capitalizedDataToExport)
            const csvOutput = Xlsx.utils.sheet_to_csv(worksheet);
            const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' })
            const url = URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = url
            a.download = excelDownloadName.replace('.xlsx', '.csv') // Ensure filename is CSV
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            URL.revokeObjectURL(url)
        }
    }

    const header = (
        <div className="d-flex justify-content-end">
            <Button id={props.id + '_container_transmission_dataTable_export_csv'} className="mr-3 justify-content-end" label="CSV Export" icon="fa-light fa-file-import" onClick={() => handleExport(ExportFormat.Csv)} disabled={hasTransmissionsModified || isTransmissionsOriginalEmpty } /> 
            <Button id={props.id + '_container_transmission_dataTable_export_excel'} className="mr-3" label="Excel Export" icon="fa-light fa-file-import" onClick={() => handleExport(ExportFormat.Excel)} disabled={hasTransmissionsModified || isTransmissionsOriginalEmpty } />
            <Button id={props.id + '_container_transmission_add'} label="Add Transmission" icon="fa-light fa-plus" onClick={handleAddTransmission} />
        </div>
    )
    const displayActions = function (rowData, rowIndex) {
        return (
            <div id={props.id + '_avList_TransmissionGrid_actions'} className="d-flex justify-content-start align-items-center">
                <Button className="ml-2" icon="fa-light fa-trash" rounded onClick={() => handleDeleteTransmission(rowData, rowIndex.rowIndex)} />
            </div>
        )
    }

    const transmissionColumnDefinitions = [
        { title: 'Date', mapsToField: 'date', filter: false, filterMatchMode: FilterMatchMode.DATE_IS, sortable: true, editable: true },
        { title: 'Time', mapsToField: 'time', filterMatchMode: FilterMatchMode.EQUALS, filter: false, sortable: false, editable: true },
        { title: 'Channel', mapsToField: 'channel', editable: true },
        { actions: true, template: displayActions }
    ]

    return (
        <div id={props.id + '_transmissions'} >
            <Toast ref={toast} />
            <StaticCommonGrid
                id={props.id + '_transmissions_commonGrid'}
                dataKey="id"
                ref={childRef}
                scrollHeight={scrollHeight}
                data={transformTransmissions(allTransmissions)}
                originalData={transformTransmissions(transmissionsOriginal)}
                totalCount={transformTransmissions(allTransmissions).length}
                emptyMessage="No Transmissions"
                columnDefinitions={transmissionColumnDefinitions}
                showPaginator={false}
                header={header}
                excelDownloadPath={excelDownloadPath}
                excelDownloadName={excelDownloadName}
                allowRowEdits={true}
                validateData={validateData}
                onSave={handleRowEditSave}
                setSortAction={setAudiovisualSortAction}
            />
        </div>
    )
}