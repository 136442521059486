import { React, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectEpisodeListRowInEditCount, selectAudioVisualResultHasChanges, selectEpisodeListResultUpdateStatus, selectShowEpisodeDataUpdate, selectEpisodeDataUpdateKey, selectEpisodeListUpdateRequest, selectEpisodeListResultPresentation, selectAudiovisualFetchResults, selectAudiovisualFetchRequest, selectAudiovisualCurrentPage, selectAudiovisualTotalPages, selectAudiovisualRows, selectAudiovisualFirst, selectAudiovisualFetchStatus, selectAudiovisualFilter, selectAudiovisualSort } from './store/selectors'
import { selectUserName } from 'providers/Authentication/store/selectors'
import { episodeListstartEditing,episodeListstopEditing,clearAudiovisualFullDraftResultsAction,setShowDataUpdateAction,setEpisodeListUpdateStatusAction, setAudiovisualFetchStatusAction, setAudiovisualFetchResultsAction, setAudiovisualTotalPagesAction, setAudiovisualFirstAction, setAudiovisualRowsAction, setAudiovisualCurrentPageAction, setAudiovisualExcelExportAction, clearAudiovisualDraftResultsAction, setAudiovisualFilterAction, setAudiovisualSortAction, seAudiovisualDraftResultsAction } from './store/actions'
import { selectAvApiHost } from 'globalSelectors' 
import CommonGrid from 'components/common/CommonGrid'
import 'primeicons/primeicons.css'
import { Button } from 'primereact/button';
import { push } from 'redux-first-history';
import { FilterMatchMode } from 'primereact/api'
import AudioVisualLandingApi from 'api/DataEntry/Landing/AudioVisual'
import { Badge } from 'primereact/badge'
import { fileDownload } from 'api/FileDownload/fileDownload'
import { setProgrammeFetchResultsAction, setIsNewProgrammeAction, setProgrammeGuidAction, setEpisodeGuidAction, setEpisodeFetchResultsAction } from 'components/Body/data-entry/AudioVisual/store/actions'
import ProgrammeDataListUpdate from 'api/DataListUpdate/AudioVisualLanding'
import { Toast } from 'primereact/toast'
import { isFilmByFormatId } from 'modules/Constants'


const csvDownloadPathSuffix = '/Programme/csvexport?'
const csvDownloadName = 'AudioVisual.csv'
const excelDownloadPathSuffix = '/Programme/excelexport?'
const excelDownloadName = 'AudioVisual.xlsx'
export function AudioVisualContent(props) {   
    const toast = useRef(null)
    const avResults = useSelector(selectAudiovisualFetchResults)
    const avPresentation = useSelector(selectEpisodeListResultPresentation)
    const currentPage = useSelector(selectAudiovisualCurrentPage)
    const first = useSelector(selectAudiovisualFirst)
    const rows = useSelector(selectAudiovisualRows)
    const filter = useSelector(selectAudiovisualFilter)
    const sort = useSelector(selectAudiovisualSort)
    const avApiHost = useSelector(selectAvApiHost)
    const isEditMode = useSelector(selectEpisodeListRowInEditCount) > 0
    const userName = useSelector(selectUserName)


    const dispatch = useDispatch()
    
    const setNewProgramme = (newProgramme) => dispatch(setProgrammeFetchResultsAction(newProgramme))
    const setNewEpisode = (newEpisode) => dispatch(setEpisodeFetchResultsAction(newEpisode))
    const setIsNewProgramme = (status) => dispatch(setIsNewProgrammeAction(status))
    const setProgrammeGuid = (guid) => dispatch(setProgrammeGuidAction(guid))
    const setEpisodeGuid = (guid) => dispatch(setEpisodeGuidAction(guid))
    const setAcceptedEpisode = (episode) => dispatch(seAudiovisualDraftResultsAction(episode))
    const clearUnchangedEpisode = (episode) => dispatch(clearAudiovisualDraftResultsAction(episode))
    const clearAudiovisualDraftResults = () => dispatch(clearAudiovisualFullDraftResultsAction())
    const showDataUpdate = useSelector(selectShowEpisodeDataUpdate)
    const dataUpdateKey = useSelector(selectEpisodeDataUpdateKey)
    const setShowDataUpdate = (show) => dispatch(setShowDataUpdateAction(show))
    const updateStatus = useSelector(selectEpisodeListResultUpdateStatus)
    const draftAvailable = useSelector(selectAudioVisualResultHasChanges) 
    const previousUpdateStatus = useRef(updateStatus)


   
    if ((previousUpdateStatus.current?.updateEpisodeListStatus !== 'complete' && updateStatus?.updateEpisodeListStatus === 'complete') || (previousUpdateStatus.current?.updateEpisodeListStatus !== 'error' && updateStatus?.updateEpisodeListStatus === 'error')) {
            dispatch(setEpisodeListUpdateStatusAction({ updateEpisodeListStatus: 'idle' }));
            setShowDataUpdate(false)         

            const responseBody = updateStatus?.responseBody || [];
            const updatedAvResults = Object.values(avPresentation || {}).map(programme => {
            const updatedProgramme = responseBody.find(response => response.programmeId === programme.programmeId)
            if (updatedProgramme && updatedProgramme.statusCode === 200) {
                return {
                    ...programme,
                    version: updatedProgramme.aggregateVersion?.version ?? programme.version
                }
            }
            return programme
        })
            const sortedUpdatedAvResults = updatedAvResults.sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn))
            clearAudiovisualDraftResults()
            dispatch(setAudiovisualFetchResultsAction({ results: sortedUpdatedAvResults, total: avResults.total }));

            const errors = responseBody.filter(response => response.statusCode !== 200);
            const errorMessage = errors.length > 0
                ? errors.map(error => `Error: ${error.message} (Status Code: ${error.statusCode})`).join('\n')
                : 'An unknown error occurred';

            if (errors.length > 0) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 3000 });
            }

            const successCount = responseBody.length - errors.length;
            if (successCount > 0) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: `Programme(s) Saved Successfully`, life: 3000 });
            }
             previousUpdateStatus.current = updateStatus
        }
  
    

    const queryParamsObject = {}
    if (filter) {
        queryParamsObject.filter = filter.toString()
    }

    if (sort) {
        queryParamsObject.sortBy = sort.toString()
    }

    const queryParams = new URLSearchParams(queryParamsObject).toString()
    const excelDownloadPath = avApiHost + excelDownloadPathSuffix + queryParams
    const csvDownloadPath = avApiHost + csvDownloadPathSuffix + queryParams

    let avResultList = []
    let avResultOriginal = []
    let avResultsCount = 0

    if (avResults) {
        avResultList = avResults['results']       
        console.log("avPresentation", avPresentation)
        if (avResultList && avResultList.length > 0) {
            const listOriginal = avResultList.reduce((acc, episode) => {
                acc[episode.episodeId] = episode
                return acc
            }, {})
            avResultOriginal = listOriginal
        }
        avResultsCount = avResults['total']

    }

    const replaceEmptyWithNull = (data) => {
        return Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, value === '' ? null : value])
        )
    }

    const isAnyEpisodeChanged = (programmeId) => {
        return avResultListWithFormattedDate.some(episode => episode.programmeId === programmeId && isRowDataChanged(episode))
    }

    const isRowDataChanged = (rowData) => {
        const originalData = avResultOriginal ? avResultOriginal[rowData.episodeId] : null
        if (!originalData) return false

        const { updatedOn: originalUpdatedOn, ...originalDataWithoutUpdatedOn } = originalData
        const { updatedOn: rowUpdatedOn, ...rowDataWithoutUpdatedOn } = rowData

        const transformedOriginalData = replaceEmptyWithNull(originalDataWithoutUpdatedOn)
        const transformedRowData = replaceEmptyWithNull(rowDataWithoutUpdatedOn)

        return JSON.stringify(transformedOriginalData) !== JSON.stringify(transformedRowData)
    }

    const handleUpdateComplete = () => {
        setShowDataUpdate(false)
    }
  
    const navigateTo = route => {
        dispatch(push(route)) // Dispatch action to navigate to the specified route
    }

    const handleEpisodeNavigation = (rowData) => {
        navigateTo(`/AudioVisual/${rowData.programmeId}/${rowData.episodeId}`)
    }



    const handleAddProgrammeClick = () => {      
            const defaultProgramme = {
                id: crypto.randomUUID(),
                animeOrLive: { id: "LI " },
                formatId: "",
                isRadio: false,
                languageId: 22,
                programmeTypeId: "",
                sapCode: "",
                comment: "",
                version: 0,
                idAId: "",
                totalEpisodes: 1,
                productionCompanies: [],
                countries: {
                    1: { countryId: 1, isPrimary: true }
                },                   
                titles: [{
                    title: '',
                    languageId: 22,
                    titleTypeId: 1
                }],
                episodes: [{
                    id: crypto.randomUUID(),
                    durationMinutes: 0,
                    idaCode: "",
                    yearOfProduction: new Date().getFullYear(),
                    number: 0,
                    series: 0,
                    sapCode: "",
                    comments: "",
                    programmeSapCode: "",
                    titles: [{
                        title: "Episode One",
                        languageId: 22,
                        titleTypeId: 1
                    }],
                    transmissions: [
                    ],
                    createdOn: new Date().toISOString(),
                    updatedOn: new Date().toISOString()
                }],
                createdOn: new Date().toISOString(),
                updatedOn: new Date().toISOString()
        }
                
            setIsNewProgramme(true)
            setProgrammeGuid(defaultProgramme?.id)
            setNewProgramme(defaultProgramme)
            const firstEpisodeId = defaultProgramme?.episodes?.[0]?.id
            const firstEpisode = defaultProgramme?.episodes?.[0]
            setEpisodeGuid(firstEpisodeId)
            setNewEpisode(firstEpisode)
            dispatch(push("/AudioVisual/" + defaultProgramme?.id + "/" + firstEpisodeId)) 


    }

    const header = (
        <div>
            <div className="row d-flex justify-content-flexend">
                <div className="col-auto">
                    <Button
                        id={props.id + '_container_av_addProgramme'}
                        label="Add Programme"
                        icon="fa-light fa-plus"
                        onClick={() => handleAddProgrammeClick()}
                        disabled={draftAvailable || isEditMode}
                    >
                    </Button>
                </div>
                <div className="col mt-1">
                    <Badge value={`Displaying ${first + 1}-${Math.min(first + rows, avResultsCount)}`}> </Badge>
                    <span class="mr-2" style={{ fontSize: "14px", fontWeight: "500", color: "black" }}> <b>of</b> </span>
                    <Badge value={avResultsCount}> </Badge>
                    <span class="mr-2" style={{ fontSize: "14px", fontWeight: "500", color: "black" }}> <b>Episodes</b> </span>
                </div>

                <div className="col-auto">
                    <Button
                        id={props.id + '_container_av_export_csv'}
                        className="mr-3 ml-2"
                        icon="fa-light fa-file-import"
                        label="CSV Export"
                        onClick={() => fileDownload(csvDownloadPath, csvDownloadName)}
                        disabled={draftAvailable}
                    >
                    </Button>
                    <Button
                        id={props.id + '_container_av_export_excel'}
                        label="Excel Export"
                        icon="fa-light fa-file-import"
                        onClick={() => fileDownload(excelDownloadPath, excelDownloadName)}
                        disabled={draftAvailable}
                    >
                    </Button>
                </div>
            </div>           
        </div>
    )

    const displayEpisode = function (rowData, field) {
        const isChanged = isAnyEpisodeChanged(rowData.programmeId)
        return (
            <div id={props.id + '_avList_commonGrid_' + field} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                <div className="d-flex ml-auto">
                    <Button icon="pi pi-copy" className="white ml-2" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />
                    <Button className="white ml-2" icon="fa-light fa-arrow-up-right" rounded onClick={() => handleEpisodeNavigation(rowData)} disabled={isChanged || isEditMode} />
                </div>
            </div>
        )
    }

    const displayEpisodeTitle = function (rowData) {
        return displayEpisode(rowData, 'episodeTitle')
    }

    const displayEpisodeCode = function (rowData) {
        return displayEpisode(rowData, 'episodeCode')
    }


    const displayProgramme = function (rowData, field) {

        const handleProgrammeNavigation = () => {
            navigateTo(`/AudioVisual/${rowData.programmeId}${isFilmByFormatId(rowData.formatId) ? `/${rowData.episodeId}` : ''}`)
        }

        const isChanged = isAnyEpisodeChanged(rowData.programmeId)
        return (
            <div id={props.id + '_avList_commonGrid_' + field} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                <div className="d-flex ml-auto">
                    <Button icon="pi pi-copy" className="white ml-2" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />
                    <Button className="white ml-1" icon="fa-light fa-arrow-up-right" rounded onClick={() => handleProgrammeNavigation()} disabled={isChanged || isEditMode} />
                </div>               
            </div>
        )
    }

    const displayProgrammeTitle = function (rowData) {
        return displayProgramme(rowData, 'programmeTitle')
    }

    const displayProgrammeCode = function (rowData) {
        return displayProgramme(rowData, 'programmeCode')
    }

    const displayIdaCode = function (rowData) {
        return displayIda(rowData, 'episodeIda')
    }
    const displayIda = function (rowData, field) {
        return (
            <div id={props.id + '_avList_commonGrid_' + field} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                {rowData[field] && (
                    <div className="d-flex ml-auto">
                        <Button icon="pi pi-copy" className="white ml-2" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />
                    </div>
                )}
            </div>
        )

    }

    const displayType = (rowData) => {
        return <i className={rowData.iconId === 1 ? "fa-light fa-tower-cell" : rowData.iconId === 2 ? "fa-light fa-film" : rowData.iconId === 3 ? "fa-light fa-tv-retro" : "fa-light fa-question"} />
    }

    const isEditable = (rowData) => {
        return !(rowData && isFilmByFormatId(rowData.formatId))
    }

    const avColumnDefinitions = [
        { title: 'Type', mapsToField: 'iconId', template: displayType },
        { title: 'Programme Code', mapsToField: 'programmeCode', filter: true, template: displayProgrammeCode, sortable: true },
        { title: 'Programme Title', mapsToField: 'programmeTitle', template: displayProgrammeTitle, filter: true, sortable: true },
        { title: 'Episode Code', mapsToField: 'episodeCode', filter: true, template: displayEpisodeCode, sortable: true },
        { title: 'Episode Title', mapsToField: 'episodeTitle', filter: true, template: displayEpisodeTitle, sortable: true, editable: true },
        { title: 'Episode No', mapsToField: 'episodeNumber', filterMatchMode: FilterMatchMode.EQUALS, filter: true,sortable: true, editable: true },
        { title: 'Series No', mapsToField: 'episodeSeries', filterMatchMode: FilterMatchMode.EQUALS, filter: true, sortable: true, editable: true },
        { title: 'Year Produced', mapsToField: 'episodeYear', filterMatchMode: FilterMatchMode.EQUALS, filter: true, sortable: true, editable: true },
        { title: 'IDA No.', mapsToField: 'episodeIda', template: displayIdaCode, filterMatchMode: FilterMatchMode.EQUALS, filter: true, sortable: true, editable: true },
        { title: 'Modified by', mapsToField: 'updatedBy', filter: true, sortable: true },
        { title: 'Last Modified', mapsToField: 'updatedOn', filterMatchMode: FilterMatchMode.DATE_IS, filter: true, sortable: true },
        { actions: true }
    ]

    const avResultListWithFormattedDate = Object.values(avPresentation || {}).map(result => ({
        ...result,
        updatedOn: new Date(result.updatedOn)
    })) || [];

    const validateData = (rowData) => {
        const errors = {}
        if (!rowData.episodeTitle || rowData.episodeTitle.trim() === '') errors.episodeTitle = 'Episode Title is Mandatory'
        if (rowData.episodeNumber === null ||  rowData.episodeNumber < 0) errors.episodeNumber = 'Episode Number is Mandatory'
        if (rowData.episodeSeries === null ||  rowData.episodeSeries < 0) errors.episodeSeries = 'Series Number is Mandatory'
        if (rowData.episodeDuration === null ||  rowData.episodeDuration < 0) errors.episodeDuration = 'Duration is Mandatory'
        if (rowData.episodeYear === null ||  rowData.episodeYear <= 0) errors.episodeYear = 'Year Produced is Mandatory'
        if (rowData.episodeIda && String(rowData.episodeIda).length !== 12) {
            errors.idaCode = 'IDA must be 12 characters long'
        }
        // Master IDA ID should be numeric
        if (rowData.episodeIda && isNaN(rowData.episodeIda)) {
            errors.idaCode = 'Episode IDA should be numeric'
        }
        // Check for duplicate IDA
        if (rowData.episodeIda) {           
            // Check if IDA is not present in episodeDraftList
            if (props.episodeListDraft && Object.keys(props.episodeListDraft).length > 0) {               
                const isIdaInDraftList = Object.values(props.episodeListDraft).some(episode => episode.episodeIda === rowData.episodeIda && episode.episodeId !== rowData.episodeId)
                if (isIdaInDraftList) {
                    errors.idaCode = 'IDA already exists'
                }
            }
        }

        //We need to call checkDuplicateIda(rowData) to validate duplicate Ida number. This is commented out because the asynchronous call is casuing the row editor to close even when validation fails. We need to investigate how to handle this properly
        return errors;
    }

    const handleRowEditSave = (rowData) => {
        const originalData = avResultOriginal ? avResultOriginal[rowData.episodeId] : null
        if ((originalData && JSON.stringify(originalData) === JSON.stringify(rowData))) {
            clearUnchangedEpisode(rowData.episodeId)
        } else {
            const updatedRowData = {
                ...rowData,
                updatedOn: new Date(),
                updatedBy: userName
            }
            setAcceptedEpisode({ [updatedRowData.episodeId]: updatedRowData })
        }
    }

    return (
        <div id={props.id + '_avList'} >
            <Toast ref={toast} />
            <AudioVisualLandingApi
                id={props.id + '_container_results_AudioVisualLanding'}
                key={currentPage + selectAudiovisualRows}
                setStatusAction={setAudiovisualFetchStatusAction} // Use the provided action for fetching results
                setResultsAction={setAudiovisualFetchResultsAction}
                requestSelector={selectAudiovisualFetchRequest}
            />
            {showDataUpdate && (
                <ProgrammeDataListUpdate
                    id={props.id + '_ProgrammeDataListUpdate_audioVisual_Episode'}
                    key={dataUpdateKey}
                    setStatusAction={setEpisodeListUpdateStatusAction}
                    requestSelector={selectEpisodeListUpdateRequest}                   
                    onComplete={handleUpdateComplete}
                />
            )}
            <CommonGrid
                id={props.id + '_avList_commonGrid'}
                keyColumnName="id"
                dataKey="episodeId"
                header={header}
                data={avResultListWithFormattedDate}
                originalData={avResultOriginal}
                totalCount={avResultsCount}
                emptyMessage="No Results"
                columnDefinitions={avColumnDefinitions}
                selectTotalPages={selectAudiovisualTotalPages}
                selectFirst={selectAudiovisualFirst}
                selectRows={selectAudiovisualRows}
                fetchStatusSelector={selectAudiovisualFetchStatus}
                setTotalPagesAction={setAudiovisualTotalPagesAction}
                setCurrentPageAction={setAudiovisualCurrentPageAction}
                setFirstAction={setAudiovisualFirstAction}
                setRowsAction={setAudiovisualRowsAction}
                setFilterAction={setAudiovisualFilterAction}
                setSortAction={setAudiovisualSortAction}
                currentPage={currentPage}
                filter={filter}
                sort={sort}
                selectionMode="multiple"
                allowRowEdits={true}
                selectionStyle="checkbox"
                excelDownloadPath={excelDownloadPath}
                excelDownloadName={excelDownloadName}
                csvDownloadPath={csvDownloadPath}
                csvDownloadName={csvDownloadName}
                validateData={validateData}
                onSave={handleRowEditSave}
                isEditable={isEditable}
                startEditing={episodeListstartEditing}
                stopEditing={episodeListstopEditing}
                isEditMode={isEditMode}
            />

        </div>
    )
}
