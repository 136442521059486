import React,{ forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import { push } from 'redux-first-history'
import { confirmPopup } from 'primereact/confirmpopup'
import { SplitButton } from 'primereact/splitbutton'
import { validateEpisodeDetails } from 'components/Body/data-entry/AudioVisual/validations'
import { selectMergeChangeEpisodeDetailToList, selectProgrammeGuid, selectProgrammeUrl,selectEpisodeDraftHasDeletedTransmissions, selectEpisodeResultHasChanges } from 'components/Body/data-entry/AudioVisual/store/selectors' 
import { setProgrammeFetchResultsAction,setProgrammeGuidAction,setIsNewEpisodeAction, setEpisodeDataUpdateKeyAction, setShowEpisodeDataUpdateAction, setShowEpisodeDataCreateAction, setEpisodeGuidAction, clearEpisodeResultsTransmissionsFromDraftsAction, setEpisodeDraftResultsTransmissionsAction, setEpisodeUpdateStatusAction, clearProgrammeDraftResultsEpisodesAction } from 'components/Body/data-entry/AudioVisual/store/actions'
import { setAudiovisualFetchResultsAction } from '../AudioVisualLanding/store/actions'

// ReSharper disable once InconsistentNaming
export const EpisodeDetailsFooter = forwardRef(({ cancelConfirmRef, episodeData, programmeData, episodePrimaryTitle, toast, isNewEpisode }) => {
    const dispatch = useDispatch() 
    const draftAvailable = useSelector(selectEpisodeResultHasChanges)  
    const deleteTransmissionAvailable = useSelector(selectEpisodeDraftHasDeletedTransmissions)  
    const programmeUrl = useSelector(selectProgrammeUrl)
    const programmeGuid = useSelector(selectProgrammeGuid)
    const updatedProgramme = useSelector(selectMergeChangeEpisodeDetailToList)

    const clearProgrammeEpisodeDraftResults = () => dispatch(clearProgrammeDraftResultsEpisodesAction())
    const clearEpisodeDraftResultsTransmissions = () => dispatch(clearEpisodeResultsTransmissionsFromDraftsAction())
    const setEpisodeDraftResultsTransmissions = (transmissions) => dispatch(setEpisodeDraftResultsTransmissionsAction(transmissions))
    const setShowDataUpdate = (show) => dispatch(setShowEpisodeDataUpdateAction(show))
    const setShowDataCreate = (show) => dispatch(setShowEpisodeDataCreateAction(show))
    const setDataUpdateKey = (key) => dispatch(setEpisodeDataUpdateKeyAction(key))
    const setIsNewEpisode = (isNew) => dispatch(setIsNewEpisodeAction(isNew))
    const setProgrammeGuid = (guid) => dispatch(setProgrammeGuidAction(guid))

    const handleCancelButtonClick = () => {
        dispatch(setAudiovisualFetchResultsAction(updatedProgramme))   
        dispatch(setEpisodeGuidAction(null))
        setIsNewEpisode(false)           
        const baseUrl = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'))       
        dispatch(push(baseUrl))
    }

    const handleCreateTrigger = () => {
        dispatch(setEpisodeUpdateStatusAction({ updateStatus: 'idle' }))
        setShowDataCreate(false)
        setDataUpdateKey(prevKey => prevKey + 1) // Increment the key to force re-render
        setShowDataCreate(true)
    }

    const handleUpdateTrigger = () => {       
        dispatch(setEpisodeUpdateStatusAction({ updateStatus: 'idle' }))
        setShowDataUpdate(false)
        setDataUpdateKey(prevKey => prevKey + 1) // Increment the key to force re-render
        setShowDataUpdate(true)       
    }
 
    /**** Cancel Logic in Footer - Begin ****/
    const accept = () => {
        if (!draftAvailable) {
            setTimeout(() => {               
                handleCancelButtonClick()               
            },
                1000);
        }
        try {
            clearProgrammeEpisodeDraftResults()

            toast.current.show({
                severity: 'info',
                summary: 'Confirmed',
                detail: 'Pending changes are cancelled',
                life: 3000
            })
            setTimeout(() => {                
                handleCancelButtonClick()              
            },
                1000);
        } catch (error) {
            console.error('Error in accept function:', error)
        }
    }

    const showCancelConfirm = (event) => {
        if (draftAvailable) {
            confirmPopup({
                target: event.currentTarget,
                message: 'You have unsaved changes. Do you want to discard them?',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                accept
            })
        }
        else {
            setTimeout(() => {                
                handleCancelButtonClick()              
            },
                1000);
        }
    }

    useImperativeHandle(cancelConfirmRef, () => ({
        showCancelConfirm: (event) => showCancelConfirm(event)
    }))
    /**** Cancel Logic in Footer - End ****/

    /**** Save Logic in Footer - Begin ****/   

    const handleTransmissionConfirmation = (event, action) => {
        const target = event.target || (event.originalEvent && event.originalEvent.currentTarget)
        confirmPopup({
            target: target,
            message: 'You are about to delete Transmissions. Do you want to continue?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: action,
            reject: rejectTransmissionChange
        })
    }

    const handleSaveAction = async (event) => {
        if (deleteTransmissionAvailable) {
            handleTransmissionConfirmation(event, acceptSave);
        } else {
            const isValid = await validateEpisodeDetails(toast, dispatch, episodeData, episodePrimaryTitle, programmeGuid, programmeUrl);
            if (!isValid) {
                return
            }
            isNewEpisode ? handleCreateTrigger() : handleUpdateTrigger()
        }
    }

    //On direct click of Accept in split button
    const splitSave = async (event) => {
        await handleSaveAction(event)
     }

    //Accept Split Buttom Items
    const saveModalItems = [
        {
            label: 'Save',
            icon: 'fa-light fa-check',
            command: async (event) => {
               await handleSaveAction(event)
            }
        },
        {
            label: 'Save and Exit',
            icon: 'fa-light fa-check',
            command: async (event) => {
                if (deleteTransmissionAvailable) {
                    handleTransmissionConfirmation(event, acceptSaveAndExit)
                } else {
                    const isValid = await validateEpisodeDetails(toast, dispatch, episodeData, episodePrimaryTitle, programmeGuid, programmeUrl);
                    if (!isValid) {
                        return
                    }
                    if (isNewEpisode) {
                        handleCreateTrigger()                        
                        setTimeout(() => {                            
                            handleCancelButtonClick();
                        }, 2000);
                    } else {
                        handleUpdateTrigger()
                        setTimeout(() => {                            
                            handleCancelButtonClick();
                        }, 2000);
                    }
                }
            }
        }
    ]

    const acceptSaveAndExit = async () => {
        const isValid = await validateEpisodeDetails(toast, dispatch, episodeData, episodePrimaryTitle, programmeGuid, programmeUrl)
        if (!isValid) {
            return
        }
        handleUpdateTrigger()
        setTimeout(() => {
            handleCancelButtonClick()
        }, 1000)
    }

    const acceptSave = async () => {
        const isValid = await validateEpisodeDetails(toast, dispatch, episodeData, episodePrimaryTitle, programmeGuid, programmeUrl)
        if (!isValid) {
            return
        }
        handleUpdateTrigger()                   
    }

    const rejectTransmissionChange = () => {        
    }

   
    /**** Save Logic in Footer - End ****/

    return (       
        <div className="app-footer episode-details-footer">
            <SplitButton className='green mr-2 mt-2' label="Save" icon="fa-light fa-check" onClick={splitSave} dropdownIcon="fa-light fa-chevron-down" model={saveModalItems} disabled={!draftAvailable && !isNewEpisode} />
            <Button label="Cancel" icon="fa-light fa-xmark" className="yellow mt-2 mr-3 " raised onClick={showCancelConfirm} ></Button>
        </div>
    )
})