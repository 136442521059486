export const setProgrammeDefaults = (original) => {
    if (original) {
        const withDefaults =
        {
            ...original,
            isRadio: original?.isRadio == null ? false : original.isRadio
        }

        return withDefaults;
    }
    return undefined
}

export const mergeChanges = (original, draft, episodeOriginal, episodeDraft) => {
    if (original) {
        const mergedTitles = draft?.titles || original?.titles || {}
        const mergedCountries = {
            ...original.countries,
            ...draft?.countries || []
        }
        const mergedProductionCompanies = {
            ...original.productionCompanies,
            ...draft?.productionCompanies || []
        }
        const mergedEpisodesList = {
            ...original.episodes,
            ...draft?.episodes || []
        }
        const draftEpisode = mergeEpisodeChanges(episodeOriginal, episodeDraft);

        // Merge the draft episode into the original episodes list
        const mergedEpisodes = Object.entries(mergedEpisodesList)
            .reduce((obj, [key, episode]) => {
                obj[key] = episode.id === draftEpisode.id
                    ? draftEpisode
                    : episode
                return obj
            }, {})
        // If the draft episode is new, add it to the list
        if (draftEpisode && Object.keys(draftEpisode).length > 0) {          
            const isDraftEpisodeInOriginal = Object.values(original.episodes).some(episode => episode.id === draftEpisode.id)
            // If draftEpisode is not found in original.episodes, add it to mergedEpisodes
            if (!isDraftEpisodeInOriginal) {
                mergedEpisodes[draftEpisode.id] = draftEpisode
            }
        }
        else {
            console.warn('draftEpisode or draftEpisode.id is undefined')
        }

        const filteredCountries = Object.entries(mergedCountries)
            .filter(([key, country]) => !country.delete)
            .reduce((obj, [key, country]) => {
                obj[key] = country
                return obj
            }, {})

        const filteredProductionCompanies = Object.entries(mergedProductionCompanies)
            .filter(([key, company]) => !company.delete)
            .reduce((obj, [key, company]) => {
                obj[key] = company
                return obj
            }, {})

        const filteredEpisodes = Object.entries(mergedEpisodes)
            .filter(([key, episode]) => !episode.delete)
            .reduce((obj, [key, episode]) => {
                obj[key] = episode
                return obj
            }, {})

        return {
            ...original,
            ...draft,
            isRadio: draft?.isRadio ?? original.isRadio,
            titles: mergedTitles,
            countries: filteredCountries,
            productionCompanies: filteredProductionCompanies,
            episodes: filteredEpisodes
        }
    }
    return {}
}

export const mergeEpisodeChanges = (original, draft) => {
    if (original) {
        const mergedTitles = draft?.titles || original?.titles || {}
        const mergedTransmissions = {
            ...original.transmissions,
            ...draft?.transmissions || []
        }

        // Filter out transmissions with delete tag set to true
        const filteredTransmissions = Object.entries(mergedTransmissions)
            .filter(([key, transmission]) => !transmission.delete)
            .reduce((obj, [key, transmission]) => {
                obj[key] = transmission
                return obj
            }, {})

        return {
            ...original,
            ...draft,
            titles: mergedTitles,
            transmissions: filteredTransmissions
        }
    }
    return {}
}

export const mergeEpisodeListChanges = (original, draft) => {
    console.log('Original:', original);
    console.log('Draft:', draft);
    if ( original && original.length > 0 ) {
        //const listOriginal = Object.entries(original).reduce((acc, episode) => {
        //    acc[episode.episodeId] = episode
        //    return acc
        //}, {})
        const listOriginal = original.reduce((acc, episode) => {
            acc[episode.episodeId] = episode;
            return acc;
        }, {})

        if (draft) {
            const draftArray = Object.values(draft);
            const listDraft = draftArray.reduce((acc, episode) => {
                acc[episode.episodeId] = episode;
                return acc;
            }, {})

            // Merge the original list with the draft changes
            const merged = {
                ...listOriginal,
                ...listDraft
            }

            console.log('Merged:', merged)

            // Convert the merged object back to an array
            return merged
        }
        return listOriginal
      
    }
    return {}   
}



export const mergeEpisodeChangesOnSave = (programmeOriginal, episodeOriginal) => {
    console.log('Original:', programmeOriginal)
    console.log('Draft:', episodeOriginal)
    if (!programmeOriginal || !episodeOriginal) {
        console.warn('ProgrammeOriginal or EpisodeOriginal is undefined')
        return {}
    }

    // Check if EpisodeOriginal does not have a title but has a titles array with titleTypeId as 1
    if (!episodeOriginal.title && Array.isArray(episodeOriginal.titles)) {
        const mainTitle = episodeOriginal.titles.find(title => title.titleTypeId === 1);
        if (mainTitle) {
            episodeOriginal.title = mainTitle.title;
        }
    }

    const mergedEpisodes = { ...programmeOriginal.episodes }

    // Check if the episode's GUID is found in Programmeoriginal.episodes
    if (mergedEpisodes[episodeOriginal.id]) {
        // Update only the keys present in Programmeoriginal.episodes with values from EpisodeOriginal
        Object.keys(mergedEpisodes[episodeOriginal.id]).forEach(key => {
            if (episodeOriginal[key] !== undefined) {
                mergedEpisodes[episodeOriginal.id][key] = episodeOriginal[key]
            }
        })
    }
    else {
        // If the episode is not found, add it to the list
        mergedEpisodes[episodeOriginal.id] = episodeOriginal
    }

    return {
        ...programmeOriginal,
        episodes: mergedEpisodes
    }
}


export const transformDraftToProgrammeInfo = (draft, original, episodeOriginal, episodeDraft, programmeOnlyIndicator) => {

    //TODO: Need to send only the changed fields; delta

    var presentation = mergeChanges(original, draft, episodeOriginal, episodeDraft)

    // Check if formatId is 1 or 5
    const isFilm = presentation.formatId === 1 || presentation.formatId === 5

    const transformed = {
        AnimeOrLive: presentation?.animeOrLive?.id || presentation?.animeOrLive || "",
        FormatId: presentation.formatId,
        IsRadio: presentation.isRadio,
        LanguageId: presentation.languageId.id? presentation.languageId.id : presentation.languageId,
        ProgrammeTypeId: presentation.programmeTypeId,
        SapCode: presentation.sapCode? presentation.sapCode : "",
        Comment: presentation.comment,
        IdAId: presentation.idAid,
        TotalEpisodes: presentation.totalEpisodes,
        ProductionCompanies: Object.values(presentation.productionCompanies).map(company => company.companyId),
        Countries: Object.values(presentation.countries).map(country => ({
            CountryId: country.countryId,
            IsPrimary: country.isPrimary
        })),
        Titles: Object.values(presentation.titles).map(title => ({
            Title: title.title,
            LanguageId: title.languageId,
            TitleTypeId: title.titleTypeId
        })),
        EpisodeInfos: (isFilm || programmeOnlyIndicator) ? Object.values(presentation.episodes).map(episode => ({
            Id: episode.id,
            DurationMinutes: episode.durationMinutes,
            IdaCode: episode.idaCode,
            YearOfProduction: episode.yearOfProduction,
            Number: isFilm ? 0 : episode.number,
            Series: isFilm ? 0 : episode.series,
            SapCode: episode.sapCode,
            Comments: episode.comments,
            ProgrammeSapCode: episode.programmeSapCode,          
            Titles: isFilm ? Object.values(presentation.titles).filter(title => title.titleTypeId === 1).map(title => ({
                Title: title.title,
                LanguageId: title.languageId,
                TitleTypeId: title.titleTypeId
            })) :
                Object.values(episode.titles ?? {}).map(title => ({
                Title: title.title,
                LanguageId: title.languageId,
                TitleTypeId: title.titleTypeId ? title.titleTypeId : 2
            })),
            Transmissions: Object.values(episode.transmissions ?? {}).map(transmission => ({
                Id: transmission.id,
                EpisodeId: episode.id,
                ChannelId: transmission.channelId,
                Date: transmission.date
            }))
        })) : []

    }
    return transformed
}


export const transformEpisodeDraftToEpisodeInfo = (episodeOriginal, episodeDraft) => {

    //TODO: Need to send only the changed fields; delta

    var presentation = mergeEpisodeChanges(episodeOriginal, episodeDraft)

    const transformed = {
        Id: presentation.id,
        DurationMinutes: presentation.durationMinutes,
        IdaCode: presentation.idaCode,
        YearOfProduction: presentation.yearOfProduction,
        Number: presentation.number,
        Series: presentation.series,
        SapCode: presentation.sapCode,
        Comments: presentation.comments,
        ProgrammeSapCode: presentation.programmeSapCode,
        Titles: Object.values(presentation.titles).map(title => ({
            Title: title.title,
            LanguageId: title.languageId,
            TitleTypeId: title.titleTypeId ? title.titleTypeId : 2
        })),
        Transmissions: Object.values(presentation.transmissions).map(transmission => ({
            Id: transmission.id,
            EpisodeId: presentation.id,
            ChannelId: transmission.channelId,
            Date: transmission.date
        }))

    }
    return transformed
}

export const transformEpisodeListDraftToEpisodeInfos = (episodeDraft) => {
    // Ensure episodeDraft is an object and transform it into an array of episode objects
    const transformed = Object.values(episodeDraft).map(episode => ({
        Id: episode.id,
        Title: episode.title,
        DurationMinutes: episode.durationMinutes,
        IdaCode: episode.idaCode ? `${episode.idaCode}` : "",
        YearOfProduction: episode.yearOfProduction,
        Number: episode.number,
        Series: episode.series
    }));

    return transformed;
}

export const transformProgrammeEpisodeListDraftToEpisodeInfos = (episodeDraft) => {   
    const episodesArray = Object.values(episodeDraft).map(episode => ({
        Id: episode.episodeId,
        ProgrammeId: episode.programmeId, 
        Title: episode.episodeTitle,
        DurationMinutes: episode.episodeDuration,
        IdaCode: episode.episodeIda ? `${episode.episodeIda}` : "",
        YearOfProduction: episode.episodeYear,
        Number: episode.episodeNumber,
        Series: episode.episodeSeries,
        Version:episode.version
    }));

    // Group episodes by programmeId
    const groupedByProgrammeId = episodesArray.reduce((acc, episode) => {
        if (!acc[episode.ProgrammeId]) {
            acc[episode.ProgrammeId] = [];
        }
        acc[episode.ProgrammeId].push(episode);
        return acc;
    }, {});
    // Create a list of ProgrammeEpisodeListInfo objects
    const programmeEpisodeListInfos = Object.entries(groupedByProgrammeId).map(([programmeId, episodes]) => {
        //all episodes in the same programme have the same version
        const version = episodes[0].Version;

        // Remove Version from each episode
        episodes.forEach(episode => {
            delete episode.Version;
            delete episode.ProgrammeId;
        });

        return {
            Id: programmeId,
            Version: version,
            EpisodeInfos: episodes
        };
    });

    return programmeEpisodeListInfos;
}