import { combineReducers } from '@reduxjs/toolkit';
import { createSelector } from 'reselect'

import { headerReducer } from './Header'
import { bodyReducers } from './Body/store'

if (headerReducer === undefined) throw new Error('headerReducer is undefined')
if (bodyReducers === undefined) throw new Error('bodyReducers is undefined')

export const componentsReducer = combineReducers({
        header: headerReducer,
        body: bodyReducers
    })

export const selectComponents = (store) => store.components
export const selectAppSettings = (store) => store.appSettings
export const selectAvHost = createSelector([selectAppSettings], (appSettings) => `https://avapi.${appSettings?.dns}`)

export const audioVisualBlacklist = [
    'DATAENTRY_AUDIOVISUAL_SET_FETCH_STATUS_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_CREATE_STATUS_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_NEW_PROGRAMME_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_NEW_EPISODE_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_FETCH_RESULT_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_ORIGINAL_EPISODES_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_ORIGINAL_VERSION_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_TITLES_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_MASTERIDA_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_ANIME_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_ISRADIO_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_LANGUAGE_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_PROGRAMMETYPE_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_FORMATTYPE_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_SAPCODE_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_PRODUCTIONCOMPANIES_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_COUNTRY_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_EPISODES_ACTION',
    'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_EPISODES_ACTION',
    'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_COUNTRY_ACTION',
    'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_ACTION',
    'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_PROGRAMMETYPE_ACTION',
    'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_FORMATTYPE_ACTION',
    'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_ISRADIO_ACTION',
    'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_MASTERIDA_ACTION',
    'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_ANIME_ACTION',
    'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_LANGUAGE_ACTION',
    'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_PRODUCTIONCOMPANIES_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_GUID',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODEFETCH_STATUS_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODEFETCH_RESULT_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODEGUID',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_RESULT_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_TRANSMISSIONS_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_RESULT_TITLE_ACTION',
    'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_TRANSMISSIONS_ACTION',
    'DATAENTRY_AUDIOVISUAL_DELETE_EPISODE_DRAFT_TRANSMISSION_ACTION',
    'DATAENTRY_AUDIOVISUAL_DELETE_EPISODE_DRAFT_RESULT_TITLE_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_PROGRAMMEUPDATE_STATUS_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_PROGRAMMEUPDATE_RESULT_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_DURATION_ACTION',
    'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_DURATION_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_YEAR_ACTION',
    'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_YEAR_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_IDA_ACTION',
    'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_IDA_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODEUPDATE_STATUS_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODELISTUPDATE_STATUS_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_SERIES_ACTION',
    'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_SERIES_ACTION',
    'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_NUMBER_ACTION',
    'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_NUMBER_ACTION'
]

export const searchAllResultsBlacklist = [
    'ALLRESULTS_AUDIOVISUAL_SET_FETCH_STATUS_ACTION',
    'ALLRESULTS_AUDIOVISUAL_SET_FETCH_RESULTS_ACTION'
]

export const globalSearchBarBlacklist = [
    '_globalSearchBar_SET_SEARCH_TERM',
    '_globalSearchBar_SET_HAS_LOST_FOCUS',
    '_globalSearchBar_SET_HAS_GAINED_FOCUS'
]

export const secondaryNavigationBlacklist = [
    'SetBreadCrumbStack',
    'PushBreadcrumbItemToStack'
]

export const searchResultsPanelsBlacklist = [
    'SEARCHRESULTS_SET_FETCH_STATUS_ACTION',
    'SEARCHRESULTS_SET_FETCH_RESULTS_ACTION',
    'SET_PAGINATION_AV_CURRENT_PAGE',
    'SET_PAGINATION_AV_TOTAL_PAGES',
    'SET_PAGINATION_AV_ROWS',
    'SET_PAGINATION_AV_FIRST',
    'SET_FILTER_AV_FILTER',
    'SET_SORT_AV_SORT',
    'SET_AV_EXPORT_EXCEL',
    'SET_AV_EXPORT_CSV'
]

export const searchResultsPreviewPanelsBlacklist = [
    'PREVIEW_AUDIOVISUAL_SET_FETCH_STATUS_ACTION',
    'PREVIEW_AUDIOVISUAL_SET_FETCH_RESULTS_ACTION',
    'SET_PANEL_VISIBILITY'   
]

export const audioVisualLandingBlacklist = [
    'AUDIOVISUAL_SET_FETCH_STATUS_ACTION',
    'AUDIOVISUAL_SET_FETCH_RESULTS_ACTION',
    'AUDIOVISUAL_SET_PAGINATION_CURRENT_PAGE',
    'AUDIOVISUAL_SET_PAGINATION_TOTAL_PAGES',
    'AUDIOVISUAL_SET_PAGINATION_ROWS',
    'AUDIOVISUAL_SET_PAGINATION_FIRST',
    'AUDIOVISUAL_SET_FILTER',
    'AUDIOVISUAL_SET_SORT',
    'AUDIOVISUAL_SET_EXPORT_EXCEL',
    'AUDIOVISUAL_SET_DRAFT_RESULTS',
    'AUDIOVISUAL_CLEAR_DRAFT_RESULTS',
    'AUDIOVISUAL_CLEAR_FULL_DRAFT_RESULTS',
    'AUDIOVISUAL_SET_EPISODELISTUPDATE_STATUS_ACTION',
    'AUDIOVISUAL_SET_SHOW_DATA_UPDATE',
    'AUDIOVISUAL_SET_DATA_UPDATE_KEY',
    'AUDIOVISUAL_EPISODELIST_START_EDITING',
    'AUDIOVISUAL_EPISODELIST_STOP_EDITING'
]

export const searchActionsBlacklist = [
    'SetSearchResultsTabSelect'
]

export const audioVisualActionsBlacklist = [
    'setAudioVisualPreviewResults',
    'setAudioVisualPreviewStatus',
    'setIsAudioVisualPreviewRequestPending'
]



