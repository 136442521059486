import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputNumber } from 'primereact/inputnumber'
import { FilterMatchMode } from 'primereact/api'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { selectAllAssets } from 'providers/AssetLoader/store/selectors'
import { AutoComplete } from 'primereact/autocomplete'
import { useSelector } from 'react-redux'
import { DomHandler } from 'primereact/utils';
import { Toast } from 'primereact/toast'

// ReSharper disable once InconsistentNaming
const StaticCommonGrid = forwardRef((props, ref) => {
    const dataTableRef = useRef(null);
    const {
        dataKey,
        totalCount,
        emptyMessage,
        columnDefinitions,
        showPaginator,
        header,
        selectionMode,
        selectionStyle,
        scrollHeight,
        rowPerPage, 
        allowRowEdits,
        originalData,
        startEditing,
        stopEditing,
        setFilterAction,
        setSortAction
    } = props

    const [validationErrors, setValidationErrors] = useState({});
    const [multiSortMeta, setMultiSortMeta] = useState([])
    const [filters, setFilters] = useState(null);
    const [filteredChannels, setFilteredChannels] = useState([])    
    const [localData, setLocalData] = useState([...props.data])

    const preprocessEmptyIdaCode = (data, allFilters) => {
        // Check if filter exists for idaCode
        if (allFilters?.idaCode?.constraints?.length > 0) {
            const filterConstraint = allFilters.idaCode.constraints[0]; // Get first constraint
            const filterMatchMode = filterConstraint.matchMode; // Check match mode
            if (filterConstraint.value != null) {
                const filterValue = filterConstraint.value?.toString()?.trim(); // Normalize filter value

                console.log("Filter value:", filterValue)

                const filteredData = data.filter(item => {
                    if ('idaCode' in item) {
                        let idaCode = item.idaCode;                       


                        // If filter is "equals" and value is ' ', return only records with idaCode === ' '
                        if (filterMatchMode === "equals" && filterValue === '') {
                            // Directly filter out null, undefined, zero, and empty strings
                            if (idaCode === null || idaCode === undefined || idaCode === '' || idaCode === 0 || idaCode.toString().trim() === '') {
                                return true
                            }
                        }

                        // Standard "equals" comparison for other values
                        return idaCode === filterValue;
                    }

                    return true; // No idaCode field, include the item
                })

                return filteredData;
            }
        }

        return data; // No filter applied, return all data
    }


   const newLocalData = preprocessEmptyIdaCode([...props.data],filters);
    if (filters && JSON.stringify(newLocalData) !== JSON.stringify(localData)) {
           setLocalData(newLocalData);
    }    
    const [selectedChannels, setSelectedChannels] = useState(() => {
        const initialSelectedChannels = {}
        localData.forEach((rowData) => {
            initialSelectedChannels[rowData[dataKey]] = rowData.channel?.name || null
        })
        return initialSelectedChannels
    })
 
    const dispatch = useDispatch()
    const { channels } = useSelector(selectAllAssets)

    const paginatorPosition = 'bottom'
    const paginatorTemplate = 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
    const currentPageReportTemplate = 'Showing results {first}-{last} of {totalRecords}'
    const defaultRowsPerPage = 50
    const rowsPerPageOptions = [10, 25, defaultRowsPerPage, 100, 500]

    useImperativeHandle(ref, () => ({
        addNewRow: () => {
            openNewRowInEditMode()
        },
        deleteRow: (rowIndex) => {
            localData.splice(rowIndex, 1)
            setLocalData([...localData])
        },
        getFilteredSortedData: () => {
            const filteredData = dataTableRef.current.getFilteredValue();
            const sortedData = dataTableRef.current.getSortedValue();
            return sortedData || filteredData || props.data;
        }
    }))
    const isFieldChanged = (rowData, field) => {
        if (!originalData) return false
        const originalRowData = Object.values(originalData).find(row => row[dataKey] === rowData[dataKey])

        if (!originalRowData) return true

        const originalValue = originalRowData[field]
        const currentValue = rowData[field]

        return (originalValue instanceof Date && currentValue instanceof Date)
            ? originalValue.getTime() !== currentValue.getTime()
            : originalValue !== currentValue
    }

    const openNewRowInEditMode = () => {
        const newRow = {}
        newRow[dataKey] = crypto.randomUUID()
        columnDefinitions.forEach((col) => {
            if (col.mapsToField !== dataKey) {
                newRow[col.mapsToField] = null
            }
        })
        setLocalData((prevLocalData) => ([newRow, ...prevLocalData]))
        localData.unshift(newRow)

        requestAnimationFrame(() => {
            DomHandler.find(document.body, '.p-row-editor-init')[0].click()
        }, 0)
    }
    const onRowEditInit = (e) => {
        if (props.startEditing) {
            dispatch(startEditing())
        }
    }
    const onRowEditSave = (e) => {
        if (props.stopEditing) {
            dispatch(stopEditing())
        }
    }

    const rowEditValidator = (rowData) => {
        const errors = props.validateData(rowData)
        if (Object.keys(errors).length > 0) {
            const rowIndex = localData.findIndex(row => row[dataKey] === rowData[dataKey])
            setValidationErrors({ [rowIndex]: errors })
            Toast.current.show({ severity: 'error', summary: 'Error', detail: Object.values(errors).join(', ') })
            return false
        }
        else {
            props.onSave(rowData)
            setValidationErrors({})
            console.log('Validation passed')
            const updatedData = [...localData]
            const rowIndex = updatedData.findIndex(row => row[dataKey] === rowData[dataKey])
            if (rowIndex !== -1) {
                updatedData[rowIndex] = rowData
                setLocalData(updatedData)
            }
            return true
        }
    }

    const onRowEditCancel = (e) => {
        const rowIndex = localData.findIndex(row => row[dataKey] === e.data[dataKey])
        const existingItem = props.data.find(row => row[dataKey] === e.data[dataKey])
        setValidationErrors({})
        if (props.stopEditing) {
            dispatch(stopEditing())
        }       
        if (!existingItem) {
            // Delete the item from localData
            if (rowIndex !== -1) {
                localData.splice(rowIndex, 1)
                setLocalData([...localData])
            }
        } else {
            const newDataKey = existingItem[dataKey]
            const selectedChannel = props.data.find(row => row[dataKey] === newDataKey)?.channel?.name || ''
            const newSelectedChannels = { ...selectedChannels, [newDataKey]: selectedChannel }
            setSelectedChannels(newSelectedChannels)
        }
    }

    const numericBodyTemplate = (rowData, field) => {
        return (
            <span>{rowData[field]}</span>
        )
    }

    const numericWithTwoDecimalBodyTemplate = (rowData, field) => {
        const value = rowData[field]
        if (value === null || value === undefined) return <span></span>
        // Round to 2 decimal places
        const roundedValue = parseFloat(value.toFixed(2))
        return <span>{roundedValue}</span>;
    }

    const idaFilterTemplate = (options, field) => {
        return (
            <InputText
                value={options.value || ''}
                onChange={(e) => {
                    const value = e.target.value === '' ? ' ' : e.target.value;
                    options.filterCallback(value, options.index);
                }}
                placeholder={`Search by ${field}`}
            />
        );
    };


    const numericFilterTemplate = (options, field) => {
        const filterOptions = options[field + 'Options'];
        return (
            <InputNumber
                value={options.value}
                options={filterOptions}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder={`Search by ${field}`}
                useGrouping={false}
            />
        )
    }  
    

    const dateBodyTemplate = (rowData) => {
        return rowData.date?.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) || ''
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />
    }
    const timeBodyTemplate = (rowData) => {
        return rowData.time?.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) || ''
    }

    const timeFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                showTime={true}
                timeOnly
                hourFormat="24"
            />
        )
    }

    const inputTextEditor = (options) => {
        const rowIndex = options.rowIndex
        return (
            <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''}
            />
        )
    }

    const search = (event) => {
        setTimeout(() => {
            let filteredChannels1

            if (!event.query.trim().length) {
                filteredChannels1 = [...channels]
            } else {
                filteredChannels1 = channels.filter((channel) => {
                    return channel.name.toLowerCase().startsWith(event.query.toLowerCase())
                })
            }          
            setFilteredChannels([...filteredChannels1]) // Force update
        }, 250)
    }

    const channelsAutoCompleteEditor = (options) => {
        if (options.field === 'channel') {
            const rowData = options.rowData
            const rowIndex = options.rowIndex
            const newDataKey = rowData[dataKey]
            const selectedChannel = selectedChannels[newDataKey] || ''           

            return (
                <AutoComplete                 
                    value={selectedChannel}
                    suggestions={filteredChannels}
                    completeMethod={search}
                    onChange={(e) => {
                        const newSelectedChannels = { ...selectedChannels }
                        newSelectedChannels[newDataKey] = e.value
                        setSelectedChannels(newSelectedChannels)
                        options.editorCallback(e.value)         
                    }}
                    onFocus={(e) => e.target.select()}
                    className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''}
                    field="name"
                />
            )
        }
        return undefined
    }

    const calendarEditor = (options) => {
        const rowIndex = options.rowIndex
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                showTime={false}
                dateFormat="dd/mm/yy"
                className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''}
            />
        )
    }

    const timeEditor = (options) => {
        const rowIndex = options.rowIndex
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                showTime={true}
                timeOnly={true}
                hourFormat="24"
                className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''}
            />
        )
    }

    const numericEditor = (options) => {
        const rowIndex = options.rowIndex
        return (
            <InputNumber
                value={options.value}
                onValueChange={(e) => options.editorCallback(e.target.value)}
                className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''}
                useGrouping={false}
            />
        )
    }

    const yearBodyTemplate = (rowData, field) => {
        const value = rowData[field]
        return value instanceof Date ? value.getFullYear() : new Date(value, 0, 1).getFullYear()
    }

    const yearEditor = (options) => {
        const rowIndex = options.rowIndex
        return (
            <Calendar
                value={options.value ? new Date(options.value, 0, 1) : null}
                onChange={(e) => options.editorCallback(e.target.value instanceof Date ? e.target.value.getFullYear() : e.target.value)}
                view="year"
                dateFormat="yy"
                className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''} />
        )
    } 
      
    const createColumn = function (title, mapsToField, filter, actions, template, sortable, filterMatchMode, editable) {
        const filterAttributes = {
            filter,
            filterPlaceholder: `Search by  ${title}`
        }
        const sortAttributes = {
            sortable
        }
        if (sortable) {
            sortAttributes.required = true
            sortAttributes.disabled = false
        }
        else {
            sortAttributes.required = false
            sortAttributes.disabled = true
        }
        if (filter) {
            filterAttributes.required = true
            filterAttributes.disabled = false
        }
        else {
            filterAttributes.required = false
            filterAttributes.disabled = true
        }
        if (filterMatchMode === FilterMatchMode.EQUALS) {
            if (mapsToField === 'time') {
                return (
                    <Column
                        {...sortAttributes}
                        key={mapsToField}
                        field={mapsToField}
                        header={title}
                        dataType="numeric"
                        body={(rowData) => (
                            <span className={isFieldChanged(rowData, mapsToField) ? 'changed-field' : ''}>
                                {timeBodyTemplate(rowData, mapsToField)}
                            </span>
                        )}
                        editor={editable ? (options) => timeEditor(options) : null}
                        filterElement={(options) => timeFilterTemplate(options)}
                        {...filterAttributes}
                    />
                )
            } else if (mapsToField === 'yearOfProduction') {
                return (
                    <Column
                        {...sortAttributes}
                        key={mapsToField}
                        field={mapsToField}
                        header={title}
                        dataType="numeric"
                        body={(rowData) => (
                            <span className={isFieldChanged(rowData, mapsToField) ? 'changed-field' : ''}>
                                {yearBodyTemplate(rowData, mapsToField)}
                            </span>
                        )}
                        editor={editable ? (options) => yearEditor(options) : null}
                        filterElement={(options) => numericFilterTemplate(options, mapsToField)}
                        {...filterAttributes}
                    />
                )
            } else if (mapsToField === 'idaCode') {
                return (
                    <Column
                        {...sortAttributes}
                        key={mapsToField}
                        field={mapsToField}
                        header={title}
                        dataType="numeric"
                        body={(rowData) => (
                            <span className={isFieldChanged(rowData, mapsToField) ? 'changed-field' : ''}>
                                {rowData[mapsToField] ? (template ? template(rowData) : rowData[mapsToField]) : rowData[mapsToField]}
                            </span>
                        )}
                        editor={editable ? (options) => inputTextEditor(options) : null}
                        filterElement={(options) => idaFilterTemplate(options, mapsToField)}                       
                        {...filterAttributes}
                    />
                );
            } else if (mapsToField === 'minutes') {
                return (
                    <Column
                        {...sortAttributes}
                        key={mapsToField}
                        field={mapsToField}
                        header={title}
                        dataType="numeric"
                        body={(rowData) => (
                            <span className={isFieldChanged(rowData, mapsToField) ? 'changed-field' : ''}>
                                {numericWithTwoDecimalBodyTemplate(rowData, mapsToField)}
                            </span>
                        )}
                        editor={editable ? (options) => inputTextEditor(options) : null}
                        {...filterAttributes}
                    />
                )
            } else {
                return (
                    <Column
                        {...sortAttributes}
                        key={mapsToField}
                        field={mapsToField}
                        header={title}
                        dataType="numeric"
                        body={(rowData) => (
                            <span className={isFieldChanged(rowData, mapsToField) ? 'changed-field' : ''}>
                                {numericBodyTemplate(rowData, mapsToField)}
                            </span>
                        )}                        
                        editor={editable ? (options) => numericEditor(options) : null}
                        filterElement={(options) => numericFilterTemplate(options, mapsToField)}
                        {...filterAttributes}
                    />
                )
            }
        }

        if (filterMatchMode === FilterMatchMode.DATE_IS) {
            return (
                <Column
                    {...sortAttributes}
                    key={mapsToField}
                    field={mapsToField}
                    header={title}
                    dataType="date"
                    body={(rowData) => (
                        <span className={isFieldChanged(rowData, mapsToField) ? 'changed-field' : ''}>
                            {dateBodyTemplate(rowData)}
                        </span>
                    )}

                    editor={editable ? (options) => calendarEditor(options) : null}
                    filterElement={(options) => dateFilterTemplate(options)}
                    {...filterAttributes}
                />
            )
        }

        if (mapsToField === 'channel') {
            return (
                <Column
                    {...sortAttributes}
                    key={mapsToField}
                    field={mapsToField}
                    header={title}
                    body={(rowData) => (
                        <span className={isFieldChanged(rowData, mapsToField) ? 'changed-field' : ''}>
                            {rowData.channel?.name}
                        </span>
                    )}                    
                    editor={editable ? (options) => channelsAutoCompleteEditor(options) : null}
                    {...filterAttributes}
                />
            )
        }

        if (actions) {
            return (
                <Column
                    body={template}
                    style={{ textAlign: 'left', borderLeft: 'none' }}
                    colSpan={2}
                />
            )
        }

        return (
            <Column
                {...sortAttributes}
                key={mapsToField}
                field={mapsToField}
                header={title}
                body={(rowData) => (
                    <span className={isFieldChanged(rowData, mapsToField) ? 'changed-field' : ''}>
                        {template ? template(rowData) : rowData[mapsToField]}
                    </span>
                )}
                editor={editable ? (options) => inputTextEditor(options) : null}
                {...filterAttributes}
            />
        )
    }

    const onFilter = (e) => {
        setFilters(e.filters)
        dispatch(setFilterAction(e.filters))
    }

    const onSort = (e) => {
        setMultiSortMeta(e.multiSortMeta)
        dispatch(setSortAction(e.multiSortMeta))
    }

    //TODO : Andy L says the scroll height being set this way is not ideal. It should be set in the parent component.
    return (
        <div id={props.id + '_container'}>
            <Toast ref={Toast} />
            <DataTable
                ref={dataTableRef}
                id={props.id + '_container_dataTable'}
                value={localData}
                size="small"
                emptyMessage={emptyMessage}
                selectionMode={selectionStyle === "checkbox" ? "checkbox" : "single"}
                scrollable
                scrollHeight={scrollHeight ? scrollHeight : (showPaginator ? "250px" : "290px")}
                showGridlines
                stripedRows
                removableSort
                filters={filters}
                paginator={showPaginator}
                paginatorPosition={paginatorPosition}
                paginatorTemplate={paginatorTemplate}
                currentPageReportTemplate={currentPageReportTemplate}
                rows={rowPerPage || defaultRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                className="datatable-responsive"
                dataKey={dataKey}
                multiSortMeta={multiSortMeta}
                sortMode="multiple"
                onSort={onSort}
                totalRecords={totalCount}
                header={header}
                onFilter={onFilter}
                editMode="row"
                rowEditValidator={rowEditValidator}
                onRowEditCancel={onRowEditCancel}
                onRowEditInit={onRowEditInit}
                onRowEditSave={onRowEditSave}
            >
                {selectionMode === "multiple" && <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>}
                {columnDefinitions.filter(col => !col.actions).map((col) =>
                    createColumn(col.title, col.mapsToField, col.filter, col.actions, col.template, col.sortable, col.filterMatchMode, col.editable)
                )}
                {allowRowEdits && <Column header="Actions" rowEditor={true} bodyStyle={{ display: 'flex', justifyContent: 'flex-end', borderRight: 'none', height: '100%' }} >
                </Column>}
                {columnDefinitions.filter(col => col.actions).map((col) =>
                    createColumn(col.title, col.mapsToField, col.filter, col.actions, col.template, col.sortable, col.filterMatchMode))}
            </DataTable>
        </div>
    )
})

export default StaticCommonGrid