import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { EpisodeDetailsHeader } from 'components/Body/data-entry/AudioVisual/EpisodeDetailsHeader'
import { EpisodeDetailsContent } from 'components/Body/data-entry/AudioVisual/EpisodeDetailsContent'
import EpisodesList from 'components/Body/data-entry/AudioVisual/EpisodesList'
import { EpisodeDetailsFooter } from './EpisodeDetailsFooter'
import { EpisodeListFooter } from './EpisodeListFooter'
import { selectAudioVisualEpisodeListFromDraft, selectEpisodeResultPresentation, selectIsNewProgramme } from './store/selectors'

// ReSharper disable once InconsistentNaming
export const EpisodeDetails = (props) => {
    //Deconstruct props
    const {
        className,
        toast,
        primaryTitle,
        altTitles,
        resultsDataForEdit,
        isEpisodeRequired,
        episodeId,
        isFilm,
        assetLanguages
    } = props

    const episodeDetailsRef = useRef(null);
    const isNewProgramme = useSelector(selectIsNewProgramme)
    var episodeData = useSelector(selectEpisodeResultPresentation)
    var episodeListDraft = useSelector(selectAudioVisualEpisodeListFromDraft)
    let episodePrimaryTitle = episodeData && episodeData.titles && episodeData.titles.length > 0 && episodeData.titles.find(title => title.titleTypeId === 1)
    const isNewEpisode = episodeData.sapCode === null || episodeData.sapCode === ''
    return (
        <div className={className}>
            <EpisodeDetailsHeader resultsDataForEdit={resultsDataForEdit} isNewProgramme={isNewProgramme} />
            {isEpisodeRequired ? <EpisodeDetailsContent toast={toast} ref={episodeDetailsRef} id={props.id} episodeId={episodeId} resultsData={resultsDataForEdit} programmePrimaryTitle={primaryTitle} otherProgrammeTitles={altTitles} languages={assetLanguages} /> : <div className='alcs-panel'><EpisodesList id={props.id} isFilm={isFilm} episodeListDraft={episodeListDraft} /></div>}
            {isEpisodeRequired ? (
                (isFilm || isNewProgramme) ? null : (
                    <EpisodeDetailsFooter
                        episodeData={episodeData}
                        episodePrimaryTitle={episodePrimaryTitle}
                        toast={toast}
                        isFilm={isFilm}
                        programmeData= {resultsDataForEdit}
                        isNewEpisode={isNewEpisode}
                    />
                )
            ) : (
                <EpisodeListFooter
                    episodeList={episodeListDraft}
                    toast={toast}
                    programmeData={resultsDataForEdit}
                    isFilm={isFilm}
                />
            )}
        </div>
    )
}