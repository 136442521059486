import React, { forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import { push } from 'redux-first-history'
import { confirmPopup } from 'primereact/confirmpopup'
import { SplitButton } from 'primereact/splitbutton'
import { validateEpisodeListDetails } from 'components/Body/data-entry/AudioVisual/validations'
import { selectAudioVisualEpisodeListHasChanges, selectEpisodeListRowInEditCount, selectProgrammeUrl } from 'components/Body/data-entry/AudioVisual/store/selectors'
import { setEpisodeDataUpdateKeyAction, setShowEpisodeDataUpdateAction, setEpisodeGuidAction, clearProgrammeDraftResultsEpisodesListAction, setEpisodeUpdateStatusAction } from 'components/Body/data-entry/AudioVisual/store/actions'

// ReSharper disable once InconsistentNaming
export const EpisodeListFooter = forwardRef(({ cancelConfirmRef, episodeList, programmeData, toast }) => {
    const dispatch = useDispatch()
    const draftAvailable = useSelector(selectAudioVisualEpisodeListHasChanges)  //check list has changes  
    const isEditMode = useSelector(selectEpisodeListRowInEditCount) > 0
    const programmeUrl = useSelector(selectProgrammeUrl)
    const clearProgrammeEpisodeListDraftResults = () => dispatch(clearProgrammeDraftResultsEpisodesListAction())
    const setShowDataUpdate = (show) => dispatch(setShowEpisodeDataUpdateAction(show))
    const setDataUpdateKey = (key) => dispatch(setEpisodeDataUpdateKeyAction(key))

    const handleCancelButtonClick = () => {
        dispatch(setEpisodeGuidAction(null))
        const currentUrl = window.location.href
        const baseUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'))
        dispatch(push(baseUrl))
    }

    const handleUpdateTrigger = () => {
        dispatch(setEpisodeUpdateStatusAction({ updateEpisodeListStatus: 'idle' }))
        setShowDataUpdate(false)
        setDataUpdateKey(prevKey => prevKey + 1) // Increment the key to force re-render
        setShowDataUpdate(true)
    }

    /**** Cancel Logic in Footer - Begin ****/
    const accept = () => {
        try {
            clearProgrammeEpisodeListDraftResults()   
            window.location.reload()
            setTimeout(() => {
                toast.current.show({
                    severity: 'info',
                    summary: 'Confirmed',
                    detail: 'Pending changes are cancelled',
                    life: 3000
                })
            }, 1000)
            
        } catch (error) {
            console.error('Error in accept function:', error)
        }
    }

    const showCancelConfirm = (event) => {
        if (draftAvailable) {
            confirmPopup({
                target: event.currentTarget,
                message: 'You have unsaved draft changes. Are you sure you want to cancel?',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                accept
            })
        }
        else {
            handleCancelButtonClick()
        }
    }

    useImperativeHandle(cancelConfirmRef, () => ({
        showCancelConfirm: (event) => showCancelConfirm(event)
    }))

    /**** Cancel Logic in Footer - End ****/
    /**** Save Logic in Footer - Begin ****/
    //On direct click of Accept in split button  
    const splitSave = async (event) => {
        const isValid = await validateEpisodeListDetails(toast, programmeData, episodeList, programmeUrl)
        if (!isValid) {
            return
        }
        handleUpdateTrigger()
    }

    //Accept Split Buttom Items
    const saveModalItems = [
        {
            label: 'Save',
            icon: 'fa-light fa-check',
            command: async (event) => {
                const isValid = await validateEpisodeListDetails(toast, programmeData, episodeList, programmeUrl)
                if (!isValid) {
                    return
                }
                handleUpdateTrigger()
            }
        },
        {
            label: 'Save and Exit',
            icon: 'fa-light fa-check',
            command: async () => {
                const isValid = await validateEpisodeListDetails(toast, programmeData, episodeList, programmeUrl)
                if (!isValid) {
                    return
                }
                handleUpdateTrigger();
                setTimeout(() => {
                    handleCancelButtonClick()                   
                }, 1000)
            }
        }
    ]

    /**** Save Logic in Footer - End ****/
    return (
        <div className="app-footer episode-details-footer">
            <SplitButton className='green mr-2 mt-2' label="Save" icon="fa-light fa-check" onClick={splitSave} dropdownIcon="fa-light fa-chevron-down" model={saveModalItems} disabled={!draftAvailable || isEditMode} />
            <Button label="Cancel" icon="fa-light fa-xmark" className="yellow mt-2 mr-3 " raised onClick={showCancelConfirm} disabled={!draftAvailable || isEditMode} ></Button>
        </div>
    )
})