import React, { forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import { push } from 'redux-first-history'
import { confirmPopup } from 'primereact/confirmpopup'
import { SplitButton } from 'primereact/splitbutton'
import { validateEpisodeListDetails } from 'components/Body/data-entry/AudioVisualLanding/validations'
import { selectAudioVisualResultHasChanges, selectEpisodeListRowInEditCount } from 'components/Body/data-entry/AudioVisualLanding/store/selectors'
import { selectProgrammeUrl } from 'components/Body/data-entry/AudioVisual/store/selectors' 
import { clearAudiovisualDraftResultsAction, clearAudiovisualFullDraftResultsAction, setShowDataUpdateAction, setDataUpdateKeyAction, setEpisodeListUpdateStatusAction } from 'components/Body/data-entry/AudioVisualLanding/store/actions'

//// ReSharper disable once InconsistentNaming
export const AudioVisualLandingFooter = forwardRef(({ cancelConfirmRef, episodeList, toast }) => {
    const dispatch = useDispatch()
    const draftAvailable = useSelector(selectAudioVisualResultHasChanges)  //check list has changes   
    const programmeUrl = useSelector(selectProgrammeUrl)
    const clearAudiovisualDraftResults = () => dispatch(clearAudiovisualFullDraftResultsAction())
    const setShowDataUpdate = (show) => dispatch(setShowDataUpdateAction(show))
    const setDataUpdateKey = (key) => dispatch(setDataUpdateKeyAction(key))
    const isEditMode = useSelector(selectEpisodeListRowInEditCount) > 0

    const handleUpdateTrigger = () => {
        dispatch(setEpisodeListUpdateStatusAction({ updateEpisodeListStatus: 'idle' }))
        setShowDataUpdate(false)
        setDataUpdateKey(prevKey => prevKey + 1) // Increment the key to force re-render
        setShowDataUpdate(true)
    }

//    /**** Cancel Logic in Footer - Begin ****/
    const accept = () => {
        if (draftAvailable) {                   
            try {
                clearAudiovisualDraftResults()
                toast.current.show({
                    severity: 'info',
                    summary: 'Confirmed',
                    detail: 'Pending changes are cancelled',
                    life: 3000
                })                
            } catch (error) {
                console.error('Error in accept function:', error)
            }
        }
    }

    const showCancelConfirm = (event) => {
        if (draftAvailable) {
            confirmPopup({
                target: event.currentTarget,
                message: 'You have unsaved changes. Do you want to discard them?',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                accept
                
            })
        }
        else {            
            console.log('No unsaved changes');
        }
    }



//    /**** Cancel Logic in Footer - End ****/

//    /**** Save Logic in Footer - Begin ****/

    //On direct click of Accept in split button
    const splitSave = async (event) => {
        const isValid = await validateEpisodeListDetails(toast, episodeList, programmeUrl)
        if (!isValid) {
            return
        }
        handleUpdateTrigger()
    }

    /**** Save Logic in Footer - End ****/

    return (
        <div className="app-footer episode-details-footer">   
            <Button label="Save&nbsp;&nbsp;&nbsp;&nbsp;" icon="fa-light fa-check" className='green mr-3 mt-2'   raised onClick={splitSave} disabled={!draftAvailable || isEditMode} />
            <Button label="Cancel" icon="fa-light fa-xmark" className="yellow mt-2 mr-3 " raised onClick={showCancelConfirm} disabled={!draftAvailable || isEditMode} ></Button>
        </div>
    )
})