import React, { useState, useEffect } from 'react'
import StaticCommonGrid from 'components/common/StaticCommonGrid'
import { useSelector } from 'react-redux'
import {selectEpisodeResultOriginal} from 'components/Body/data-entry/AudioVisual/store/selectors'
import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button';
import * as Xlsx from 'xlsx'
import { ExportFormat } from 'modules/Constants'

export function Contributions(props) {
    const isFilm = props.isFilm
    const excelDownloadName = 'Contributions.xlsx'
    var episodeData = useSelector(selectEpisodeResultOriginal)
    let contributions = episodeData && episodeData.contributions ? episodeData.contributions : []

    const [scrollHeight, setScrollHeight] = useState(calculateScrollHeight())

    //Andy L approves this useEffect
    useEffect(() => {
        const handleResize = () => {
            setScrollHeight(calculateScrollHeight())
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    //TODO: We should not be changing styles in JS. This should be done in CSS. The staticcommonGrid doesnot allow for this to be done in CSS
    function calculateScrollHeight() {
        const adjustedHeight = window.innerHeight / window.devicePixelRatio;
        let height = adjustedHeight * 0.2
        if (!isFilm) {
            height = height - 30
        }
        return `${height}px`
    } 
    const displayActions = function () {
        return (
            <div id={props.id + '_avList_ContributionGrid_actions'} className="d-flex justify-content-start align-items-center">
                <Button className="ml-2" icon="fa-light fa-pen" rounded disabled />             
                <Button className="ml-2" icon="fa-light fa-arrow-up-right" rounded disabled />
            </div>
        )
    }

    const handleExport = (format) => {
        const columnMappings = contributionColumnDefinitions
            .filter(col => col.mapsToField)// Ignore columns without `mapsToField`
            .map(col => ({ title: col.title, field: col.mapsToField }))

            const dataToExport = contributions.map(item =>
                Object.fromEntries(columnMappings.map(({ title, field }) => [
                    title, 
                    field === 'minutes' ? parseFloat(item[field].toFixed(2)) : item[field]
                ]))
            )
        
        if (format === ExportFormat.Excel) {
            const worksheet = Xlsx.utils.json_to_sheet(dataToExport)
            const workbook = Xlsx.utils.book_new()
            Xlsx.utils.book_append_sheet(workbook, worksheet, 'Contributions')
            Xlsx.writeFile(workbook, excelDownloadName)
        } else if (format === ExportFormat.Csv) {
            const worksheet = Xlsx.utils.json_to_sheet(dataToExport)
            const csvOutput = Xlsx.utils.sheet_to_csv(worksheet);
            const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' })
            const url = URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = url
            a.download = excelDownloadName.replace('.xlsx', '.csv') // Ensure filename is CSV
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            URL.revokeObjectURL(url)
        }
    }

    const header = (
        <div className="d-flex justify-content-end">
            <Button id={props.id + '_container_contribution_A%'} className="mr-3" label="A%" disabled />
            <Button id={props.id + '_container_contribution_dataTable_export_csv'} className="mr-3" label="CSV Export" icon="fa-light fa-file-import" onClick={() => handleExport(ExportFormat.Csv)} />
            <Button id={props.id + '_container_contribution_dataTable_export_excel'} className="mr-3" label="Excel Export" icon="fa-light fa-file-import" onClick={() => handleExport(ExportFormat.Excel)} />
            <Button id={props.id + '_container_contribution_add'} label="Add Contributor" icon="fa-light fa-plus" disabled/>                
        </div>
    )

    const contributionColumnDefinitions = [
        { title: 'Author Key', mapsToField: 'sapCode', filter: false, filterMatchMode: FilterMatchMode.STARTS_WITH, sortable: false, editable: false },
        { title: 'Contributor Name', mapsToField: 'contributorName', filter: false, filterMatchMode: FilterMatchMode.STARTS_WITH, sortable: false, editable: false },
        { title: 'Contribution', mapsToField: 'type', filter: false, filterMatchMode: FilterMatchMode.STARTS_WITH, sortable: false, editable: false },
        { title: '%', mapsToField: 'percentage', editable: false },
        { title: 'Minutes Worked', mapsToField: 'minutes', filter: false, filterMatchMode: FilterMatchMode.EQUALS, sortable: false, editable: false },
        { title: 'IPI No.', mapsToField: 'ipiNo', filter: false, filterMatchMode: FilterMatchMode.EQUALS, sortable: false, editable: false },
        { expand: true, title: 'Actions', template: displayActions }
    ]
    
    return (
        <div id={props.id + '_contributions'}>
            <StaticCommonGrid
                id={props.id + '_contributions_commonGrid'}
                keyColumnName="sapCode"
                scrollHeight={scrollHeight}
                data={contributions}
                totalCount={contributions.length}
                emptyMessage="No Contributions"
                columnDefinitions={contributionColumnDefinitions}
                showPaginator={false}
                header={header}
            />
        </div>
    )
}