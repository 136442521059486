export const setTvFilmRadioFetchStatusAction = (status) => {
    return { type: 'SEARCHRESULTS_SET_FETCH_STATUS_ACTION', payload: status }
}

export const setTvFilmRadioFetchResultsAction = (results) => {
    return { type: 'SEARCHRESULTS_SET_FETCH_RESULTS_ACTION', payload: results }
}

export const setAvCurrentPageAction = (currentPage) => {
    return { type: 'SET_PAGINATION_AV_CURRENT_PAGE', payload: currentPage }
}

export const setAvTotalPagesAction = (totalPages) => {
    return { type: 'SET_PAGINATION_AV_TOTAL_PAGES', payload: totalPages }
}

export const setAvRowsAction = (rowCount) => {
    return { type: 'SET_PAGINATION_AV_ROWS', payload: rowCount }
}

export const setAvFirstAction = (first) => {
    return { type: 'SET_PAGINATION_AV_FIRST', payload: first }
}

export const setAvFilterAction = (filter) => {
    return { type: 'SET_FILTER_AV_FILTER', payload: filter }
}

export const setAvSortAction = (sort) => {
    return { type: 'SET_SORT_AV_SORT', payload: sort }
}

export const setAvExcelExportAction = (searchTerm) => {
    return { type: 'SET_AV_EXPORT_EXCEL', payload: searchTerm }
}

export const setAvCsvExportAction = (searchTerm) => {
    return { type: 'SET_AV_EXPORT_CSV', patload: searchTerm }
}



