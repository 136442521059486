import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { setProgrammeDraftResultsIsRadioAction, setProgrammeDraftResultsAnimeAction, setProgrammeDraftResultsProgrammeTypeAction, setProgrammeDraftResultsLanguageAction, setProgrammeDraftResultsFormatTypeAction, setProgrammeDraftResultsProductionCompaniesAction, setProgrammeDraftResultsMasterIdaAction, clearProgrammeDraftResultsProgrammeTypeAction, clearProgrammeDraftResultsFormatTypeAction, clearProgrammeDraftResultsIsRadioAction, clearProgrammeDraftResultsAnimeAction, clearProgrammeDraftResultsProductionCompaniesAction, setProgrammeDraftResultsCountryAction, clearProgrammeDraftResultsCountryAction, clearProgrammeDraftResultsAction, clearProgrammeDraftResultsMasterIdaAction, clearProgrammeDraftResultsLanguageAction } from 'components/Body/data-entry/AudioVisual/store/actions'
import { InputText } from 'primereact/inputtext'
import { RadioButton } from 'primereact/radiobutton'

import { MultiSelect } from 'primereact/multiselect'
import { ScrollPanel } from 'primereact/scrollpanel'
import { selectAllAssets } from 'providers/AssetLoader/store/selectors'
import { handlePrimaryCountryChange, handleSecondaryCountryChange, handleProductionCompaniesChange } from 'components/Body/data-entry/AudioVisual/programmeDetailsUtils'
import { validateChangeProgrammeFormat } from 'components/Body/data-entry/AudioVisual/validations'

// ReSharper disable once InconsistentNaming
export const ProgrammeDetailsContent = ({ resultsDataForEdit, programmeDataOriginal, toast }) => {
    const dispatch = useDispatch()
    const { languages, programmeTypes, programmeFormatTypes, productionCompanies, countries, animeTypes } = useSelector(selectAllAssets)
    const setAcceptedIsRadio = (isRad) => dispatch(setProgrammeDraftResultsIsRadioAction(isRad))
    const setAcceptedProgrammeType = (typeId) => dispatch(setProgrammeDraftResultsProgrammeTypeAction(typeId))
    const setAcceptedFormatType = (typeId) => dispatch(setProgrammeDraftResultsFormatTypeAction(typeId))
    const setAcceptedProductionCompanies = (companies) => dispatch(setProgrammeDraftResultsProductionCompaniesAction(companies))
    const setAcceptedMasterIda = (masterIda) => dispatch(setProgrammeDraftResultsMasterIdaAction(masterIda))
    const setAcceptedAnime = (anime) => dispatch(setProgrammeDraftResultsAnimeAction(anime))
    const setAcceptedLanguage = (language) => dispatch(setProgrammeDraftResultsLanguageAction(language))
    const clearProgrammeDraftResultsProgrammeType = () => dispatch(clearProgrammeDraftResultsProgrammeTypeAction())
    const clearProgrammeDraftResultsFormatType = () => dispatch(clearProgrammeDraftResultsFormatTypeAction())
    const clearProgrammeDraftResultsIsRadio = () => dispatch(clearProgrammeDraftResultsIsRadioAction())
    const clearProgrammeDraftResultsProductionCompanies = (companies) => dispatch(clearProgrammeDraftResultsProductionCompaniesAction(companies))
    const clearProgrammeDraftResultsMasterIda = () => dispatch(clearProgrammeDraftResultsMasterIdaAction())
    const clearProgrammeDraftResultsAnime = () => dispatch(clearProgrammeDraftResultsAnimeAction())
    const clearProgrammeDraftResultsLanguage = () => dispatch(clearProgrammeDraftResultsLanguageAction())

    const assetLanguages = languages

    let primaryCountry = Object.values(resultsDataForEdit?.countries || {}).find(country => country.isPrimary === true)
    let secondaryCountries = Object.values(resultsDataForEdit?.countries || {}).filter(country => country.isPrimary === false)
    let productionCompaniesDraft = Object.values(resultsDataForEdit?.productionCompanies || {})
    const { programmeTypeId, formatId, isRadio } = resultsDataForEdit || {}
    const { programmeTypeId: programmeTypeIdOriginal, formatId: formatIdOriginal, isRadio: isRadioOriginal } = programmeDataOriginal || {}
    let selectionValue = (isRadio ? 2 : 1) | programmeTypeId
    const availableTypes = programmeTypes.filter((t) => (t.consumedByTypes & selectionValue) > 0)
    const availableFormats = programmeFormatTypes.filter((t) => (t.consumedByProgrammeTypes & selectionValue & 3) > 0 && (t.consumedByProgrammeTypes & selectionValue & ~3))
    const setAcceptedCountry = (country) => dispatch(setProgrammeDraftResultsCountryAction(country))
    const clearAcceptedCountry = (countryId) => dispatch(clearProgrammeDraftResultsCountryAction(countryId))
    
    const onChangeProgrammeFormat = (e) => {
        
        // and only change it if we passed validation
        if (validateChangeProgrammeFormat(e.value, resultsDataForEdit)) {
            e.value === formatIdOriginal ? clearProgrammeDraftResultsFormatType() : setAcceptedFormatType(e.value?.id)

        }
        else {
            // otherwise we reset to original
            clearProgrammeDraftResultsFormatType()
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Changes cannot be accepted' })
        }
    }
    
    return (
        <div className="details-content mt-4">
            <fieldset className="mt-2">
                <legend>Details</legend>
                <ScrollPanel className="ml-3 mt-1" >
                    <div className="row mb-2">
                        <div className="col-md-8 d-flex align-items-center">
                            <div id="results-text">
                                <p className="label-input mb-1">Master IDA ID</p>
                                <div className="d-flex align-items-center">
                                    <InputText id="masterIDAid" keyfilter="int" value={resultsDataForEdit && resultsDataForEdit.idAid} onChange={(e) => (programmeDataOriginal.idAid === null && e.target.value === "") || (e.target.value === programmeDataOriginal.idAid) ? clearProgrammeDraftResultsMasterIda() : setAcceptedMasterIda(e.target.value)} className="p-inputtext-sm av-radio-option form-control mr-2" />
                                    <Button icon="pi pi-copy" className="p-button-text" rounded onClick={() => navigator.clipboard.writeText(resultsDataForEdit?.idAid || '')} />
                                </div>
                            </div>

                        </div>
                        <div className="col-md-2 mt-3 ml-3">
                            <label className="label-input mb-1">Type</label>
                            <div className="d-flex align-items-center mt-2">
                                <RadioButton inputId="av" name="type" value={false} checked={!isRadio} onChange={(e) => e.value === isRadioOriginal ? clearProgrammeDraftResultsIsRadio() : setAcceptedIsRadio(false)}
                                   />
                                <label htmlFor="av" className="mr-2 av-radio-option">AV</label>
                                <RadioButton inputId="radio" name="type" value={true} checked={isRadio} onChange={(e) => e.value === isRadioOriginal ? clearProgrammeDraftResultsIsRadio() : setAcceptedIsRadio(true)} />
                                <label htmlFor="radio" className="mr-2 av-radio-option">Radio</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-4">
                            <p className="label-input mb-1">Programme Type</p>
                            <Dropdown id="programmeType" value={availableTypes.find(type => type.id === programmeTypeId)} options={availableTypes} optionLabel="name" optionValue="id" onChange={(e) => e.value === programmeTypeIdOriginal ? clearProgrammeDraftResultsProgrammeType() : setAcceptedProgrammeType(e.value?.id)}
                                itemTemplate={(option) => (<div>
                                    {isRadio ? option.radioSapCode : option.sapCode} - {option.name}
                                </div>)}                                
                                valueTemplate={(option) => option?.name} className="custom-full-width" />
                        </div>
                        <div className="col-md-4">
                            <p className="label-input mb-1">Format</p>
                            <Dropdown
                                id="format" value={availableFormats.find(format => format.id == formatId)} options={availableFormats} optionLabel="name" optionValue="id" onChange={(e) => onChangeProgrammeFormat(e)}
                                itemTemplate={(option) => (<div>
                                    {isRadio ? option.radioSapCode : option.sapCode} - {option.name}
                                </div>)}
                                valueTemplate={(option) => option?.name} className="custom-full-width" />
                        </div>

                        {!isRadio && (
                            <div className="col-md-4">
                                <p className="label-input mb-1">Animation / Live</p>
                                {/*Use Asset loaded values for options */}
                                <Dropdown
                                    id="anime"
                                    value={resultsDataForEdit && resultsDataForEdit.animeOrLive}
                                    options={animeTypes}
                                    optionLabel="name"
                                    optionValue="id"
                                    onChange={(e) => e.value === programmeDataOriginal.animeOrLive ? clearProgrammeDraftResultsAnime() : setAcceptedAnime(e.value)}
                                    placeholder={resultsDataForEdit && resultsDataForEdit.animeOrLive && animeTypes.find(type => type.id.trim() === (resultsDataForEdit.animeOrLive.id ? resultsDataForEdit.animeOrLive.id : resultsDataForEdit.animeOrLive).trim())?.name}
                                    className="custom-full-width"
                                />
                            </div>
                        )}
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-6">
                            <p className="mb-0 label-input mb-1">Language</p>
                            <div className="d-flex align-items-center">
                                {/*Use Asset loaded values for options */}
                                <Dropdown id="language" value={resultsDataForEdit && resultsDataForEdit.languageId} filter options={assetLanguages} onChange={(e) => e.value === programmeDataOriginal.languageId ? clearProgrammeDraftResultsLanguage() : setAcceptedLanguage(e.value)} placeholder={resultsDataForEdit && resultsDataForEdit.languageId && assetLanguages.find(language => language.id === resultsDataForEdit.languageId)?.name} className="custom-full-width" optionLabel="name"
                                    optionValue="id" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <p className="label-input mb-1">Primary Country</p>
                            <Dropdown id="primaryCountry" value={countries.find(country => country.id == primaryCountry?.countryId)} filter options={countries} onChange={(e) => handlePrimaryCountryChange(e, true, primaryCountry, secondaryCountries, setAcceptedCountry, clearAcceptedCountry)} className="custom-full-width" optionLabel="name" optionValue="id" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-12">
                            <p className="mb-0 label-input mb-1">Secondary Countries</p>
                            <div className="d-flex align-items-center">
                                <MultiSelect value={secondaryCountries.map(country => country.countryId)} options={countries.filter(country => country.id !== primaryCountry?.countryId)} onChange={(e) => handleSecondaryCountryChange(e, secondaryCountries, setAcceptedCountry, clearAcceptedCountry)} optionLabel="name" optionValue="id"
                                    filter placeholder="Select Countries" className="expanding w-100 av-secondarycountry" display="chip" disabled={!primaryCountry?.countryId} readOnly={!primaryCountry?.countryId} />
                            </div>

                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-12">
                            <label htmlFor="productionCompanies" className="label-input mb-1">Production Companies</label>
                            <div className="d-flex align-items-center">
                                <MultiSelect value={productionCompaniesDraft && productionCompaniesDraft.map(company => company.companyId)} options={productionCompanies} onChange={(e) => handleProductionCompaniesChange(e, productionCompaniesDraft, setAcceptedProductionCompanies, clearProgrammeDraftResultsProductionCompanies)} optionLabel="name" optionValue="id"
                                    filter placeholder="Select Production Companies" className="expanding w-100 av-production-company" display="chip" />
                            </div>
                        </div>
                    </div>
                </ScrollPanel>
            </fieldset>
        </div>
    )
}
