import React, { useRef, useEffect } from 'react'
import StaticCommonGrid from 'components/common/StaticCommonGrid'
import { useSelector, useDispatch } from 'react-redux'
import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { selectAudioVisualEpisodeListHasChanges,selectEpisodeListRowInEditCount,selectAudiovisualFilter, selectAudiovisualSort, selectAudioVisualResultOriginal, selectAudioVisualResultPresentation, selectEpisodeDataUpdateKey, selectEpisodeListResultUpdateStatus, selectEpisodeListUpdateRequest, selectShowEpisodeDataUpdate } from 'components/Body/data-entry/AudioVisual/store/selectors'
import { setIsNewEpisodeAction, setAudiovisualFilterAction, setAudiovisualSortAction, episodeListstartEditing,episodeListstopEditing,setProgrammeDraftResultsEpisodesAction, setProgrammeEpisodesFetchResultsAction, clearProgrammeDraftResultsEpisodesAction, clearProgrammeDraftResultsEpisodesListAction, setEpisodeGuidAction, setEpisodeFetchResultsAction, setEpisodeListUpdateStatusAction, setProgrammeOriginalAggregateVersionAction, setShowEpisodeDataUpdateAction } from 'components/Body/data-entry/AudioVisual/store/actions'
import { push } from 'redux-first-history'
import DataListUpdate from 'api/DataListUpdate/AudioVisual'
import { Toast } from 'primereact/toast'
import { selectAvApiHost } from 'globalSelectors' 
import { matchConstraints } from 'modules/filterUtils'
import * as Xlsx from 'xlsx'
import { ExportFormat } from 'modules/Constants'


// ReSharper disable once InconsistentNaming
export default function EpisodesList(props) {
    const toast = useRef(null)
    const isFilm = props.isFilm
    const gridRef = useRef(null)
    const programmeData = useSelector(selectAudioVisualResultPresentation)
    const programmeDataOriginal = useSelector(selectAudioVisualResultOriginal)  
    const isEditMode = useSelector(selectEpisodeListRowInEditCount) > 0
    const episodeDataOriginal = programmeDataOriginal && programmeDataOriginal.episodes ? programmeDataOriginal.episodes : []
    let episodeData = programmeData && programmeData.episodes ? Object.values(programmeData.episodes) : []    
    const originalEpisodesCount = programmeData && programmeData.episodes ? Object.keys(programmeData.episodes).length : 0   
    const draftAvailable = useSelector(selectAudioVisualEpisodeListHasChanges) 

    const dispatch = useDispatch()
    const setAcceptedEpisode = (episode) => dispatch(setProgrammeDraftResultsEpisodesAction(episode))
    const clearUnchangedEpisode = (episode) => dispatch(clearProgrammeDraftResultsEpisodesAction(episode))
    const setNewEpisode = (newEpisode) => dispatch(setEpisodeFetchResultsAction(newEpisode))
    const setIsNewEpisode = (isNewEpisode) => dispatch(setIsNewEpisodeAction(isNewEpisode))
    const setEpisodeGuid = (guid) => dispatch(setEpisodeGuidAction(guid))
    const showDataUpdate = useSelector(selectShowEpisodeDataUpdate)
    const dataUpdateKey = useSelector(selectEpisodeDataUpdateKey)
    const setShowDataUpdate = (show) => dispatch(setShowEpisodeDataUpdateAction(show))
    const clearProgrammeEpisodeListDraftResults = () => dispatch(clearProgrammeDraftResultsEpisodesListAction())
    const updateStatus = useSelector(selectEpisodeListResultUpdateStatus)
    const avApiHost = useSelector(selectAvApiHost)
    const filter = useSelector(selectAudiovisualFilter)
    const sort = useSelector(selectAudiovisualSort)
    const excelDownloadPathSuffix = '/Programme/excelexport?'
    const excelDownloadName = 'ProgrammeEpisodes.xlsx'

    useEffect(() => {
        if (updateStatus?.updateEpisodeListStatus === 'complete') {
            dispatch(setEpisodeListUpdateStatusAction({ updateEpisodeListStatus: 'idle' }))
            setShowDataUpdate(false)
            clearProgrammeEpisodeListDraftResults()
            const episodeDataFromDraft = episodeData.reduce((acc, episode) => {
                acc[episode.id] = episode
                return acc
            }, {})
            dispatch(setProgrammeEpisodesFetchResultsAction(episodeDataFromDraft))
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Changes Saved Successfully', life: 3000 })
        } else if (updateStatus?.updateEpisodeListStatus === 'error') {
            const errorMessage = updateStatus?.status?.error?.Message || 'An unknown error occurred'
            dispatch(setEpisodeListUpdateStatusAction({ updateEpisodeListStatus: 'idle' }))
            setShowDataUpdate(false)
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Error: ${errorMessage}`, life: 3000 })
        }
    }, [updateStatus]);

    const navigateTo = route => {
        dispatch(push(route)) // Dispatch action to navigate to the specified route
    }

    const handleUpdateComplete = () => {
        setShowDataUpdate(false)
    }

    const isRowDataChanged = (rowData) => {
        const originalData = episodeDataOriginal ? episodeDataOriginal[rowData.id] : null;
        return originalData && JSON.stringify(originalData) !== JSON.stringify(rowData);
    }

    const displayActions = function (rowData) {
        const handleEpisodeNavigation = () => {
            navigateTo(`/AudioVisual/${programmeDataOriginal.id}/${rowData.id}`)
        }
        const isChanged = isRowDataChanged(rowData)
        return (
            <div id={props.id + '_avList_EpisodeListingGrid_actions'} className="d-flex justify-content-start align-items-center">
                <Button icon="fa-light fa-arrow-up-right" rounded onClick={handleEpisodeNavigation} disabled={isChanged || isEditMode} />
                <Button className="ml-2" icon="pi pi-copy" rounded disabled />
            </div>
        )
    }  
    
    const queryParamsObject = {}
    if (filter) {
        queryParamsObject.filter = filter.toString()
    }

    if (sort) {
        queryParamsObject.sortBy = sort.toString()
    }

    const queryParams = new URLSearchParams(queryParamsObject).toString()
    const excelDownloadPath = avApiHost + excelDownloadPathSuffix + queryParams
   

    const handleAddEpisode = () => {
        const defaultEpisode = {
            id: crypto.randomUUID(),
            durationMinutes: 0,
            idaCode: "",
            yearOfProduction: new Date().getFullYear(),
            number: 0,
            series: 0,
            sapCode: "",
            comments: "",
            programmeSapCode: "",
            titles: [{
                title: "Episode " + (originalEpisodesCount+1),
                languageId: 22,
                titleTypeId: 1
            }],
            transmissions: [
            ],
            createdOn: new Date().toISOString(),
            updatedOn: new Date().toISOString()
        }
        setEpisodeGuid(defaultEpisode.id)
        setNewEpisode(defaultEpisode)
        setIsNewEpisode(true)
        navigateTo(`/AudioVisual/${programmeDataOriginal.id}/${defaultEpisode.id}`)
    }

    const applyFilterAndSort = (data) => {
        let filteredData = data;

        // Apply filter
        if (filter) {
            filteredData = filteredData.filter(item => {
                return (
                    (!filter.sapCode || matchConstraints(item.sapCode, filter.sapCode)) &&
                    (!filter.title || matchConstraints(item.title, filter.title)) &&
                    (!filter.number || matchConstraints(item.number, filter.number)) &&
                    (!filter.series || matchConstraints(item.series, filter.series)) &&
                    (!filter.yearOfProduction || matchConstraints(item.yearOfProduction, filter.yearOfProduction)) &&
                    (!filter.idaCode || matchConstraints(item.idaCode, filter.idaCode)) &&
                    (!filter.durationMinutes || matchConstraints(item.durationMinutes, filter.durationMinutes))
                )
            })
        }

        // Apply sort
        if (sort) {
            filteredData = filteredData.sort((a, b) => {
                for (let i = 0; i < sort.length; i++) {
                    const { field, order } = sort[i]
                    let result = 0

                    if (field === 'title' || field === 'sapCode' || field === 'idaCode') {
                        result = a[field].localeCompare(b[field]);
                    } else if (field === 'number' || field === 'series' || field === 'yearOfProduction') {
                        result = a[field] - b[field];
                    }

                    if (result !== 0) {
                        return order * result
                    }
                }
                return 0
            })
        }

        return filteredData;
    }
    
    const handleExport = (format) => {
        const filteredAndSortedData = applyFilterAndSort(episodeData)
        const columnMappings = episodeColumnDefinitions
            .filter(col => col.mapsToField)// Ignore columns without `mapsToField`
            .map(col => ({ title: col.title, field: col.mapsToField }))
        // Extract only required fields in the specified order
        const dataToExport = filteredAndSortedData.map(item =>
            Object.fromEntries(columnMappings.map(({ title, field }) => [title, item[field]]))
        )

        if (format === ExportFormat.Excel) {
            const worksheet = Xlsx.utils.json_to_sheet(dataToExport)
            const workbook = Xlsx.utils.book_new()
            Xlsx.utils.book_append_sheet(workbook, worksheet, 'Episodes')
            Xlsx.writeFile(workbook, excelDownloadName)
        } else if (format === ExportFormat.Csv) {
            // Convert JSON data to worksheet
            const worksheet = Xlsx.utils.json_to_sheet(dataToExport)
            // Convert worksheet to CSV format
            const csvOutput = Xlsx.utils.sheet_to_csv(worksheet)

            // Create a Blob and trigger download
            const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" })
            const url = URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = url;
            a.download = excelDownloadName.replace('.xlsx', '.csv') // Ensure filename is CSV
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
            URL.revokeObjectURL(url)
        }
    }


    const header = (
        <div className="row d-flex">
            <div className="col mt-1">
                <Button id={props.id + '_container_episodeList_dataTable_add'} className="mr-3 justify-content-start" label="Add Episode" icon="fa-light fa-plus" disabled={ isFilm || draftAvailable || isEditMode } onClick={handleAddEpisode} />
            </div>
            <div className="col-auto mt-1">
                <Button id={props.id + '_container_episodeList_dataTable_export_csv'} className="mr-3 justify-content-end" label="CSV Export" icon="fa-light fa-file-import" onClick={() => handleExport(ExportFormat.Csv)} disabled={draftAvailable} />    
                <Button id={props.id + '_container_episodeList_dataTable_export_excel'} className="mr-3 justify-content-end" label="Excel Export" icon="fa-light fa-file-import" onClick={() => handleExport(ExportFormat.Excel)} disabled={draftAvailable} />           
            </div>
        </div>
    );
   

    const validateData = (rowData) => {
        const errors = {}        
        if (!rowData.title || rowData.title.trim() === '') errors.title = 'Episode Title is Mandatory'
        if (rowData.number === null || rowData.number < 0) errors.number = 'Episode Number is Mandatory'
        if (rowData.series === null || rowData.series < 0) errors.series = 'Series Number is Mandatory'
        if (rowData.durationMinutes === null || rowData.durationMinutes < 0) errors.durationMinutes = 'Duration is Mandatory'
        if (rowData.yearOfProduction === null || rowData.yearOfProduction <= 0) errors.yearOfProduction = 'Year Produced is Mandatory'

        if (rowData.idaCode && String(rowData.idaCode).length !== 12) {
            errors.idaCode = 'IDA must be 12 characters long'
        }
        // IDA ID should be numeric
        if (rowData.idaCode && isNaN(rowData.idaCode)) {
            errors.idaCode = 'Episode IDA should be numeric'            
        }
        // Check for duplicate IDA
        if (rowData.idaCode) {           
            // Check if IDA is not present in episodeDraftList
            if (props.episodeListDraft && Object.keys(props.episodeListDraft).length > 0) {
                const isIdaInDraftList = Object.values(props.episodeListDraft).some(episode => episode.idaCode === rowData.idaCode && episode.id !== rowData.id)
                if (isIdaInDraftList) {
                    errors.idaCode = 'IDA already exists'
                }
            }
        }

        //We need to call checkDuplicateIda(rowData) to validate duplicate Ida number. This is commented out because the asynchronous call is causing the row editor to close even when validation fails. We need to investigate how to handle this properly

        return errors;
    }

    const handleRowEditSave = (rowData) => {
        const originalData = episodeDataOriginal ? episodeDataOriginal[rowData.id] : null
        if ((originalData && JSON.stringify(originalData) === JSON.stringify(rowData))) {
            clearUnchangedEpisode(rowData.id)
        } else {
            setAcceptedEpisode({ [rowData.id]: rowData })
        }
    }

    const copyDataTemplate = (rowData, field) => {
        const isChanged = isRowDataChanged(rowData);
        return (
            <div id={props.id + '_avList_EpisodeListingGrid_copy'} className="d-flex justify-content-start align-items-center">
                <span>{rowData[field]}</span>   
                {(rowData[field] && rowData[field]?.toString().trim().length > 0) && (<div className="d-flex ml-auto">
                    <Button className="white ml-2" icon="pi pi-copy" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />
                </div>
                )}
            </div>
        )
    }     

    const displayEpisodeCode = function (rowData) {
        return copyDataTemplate(rowData, 'sapCode')
    }

    const displayEpisodeTitle = function (rowData) {
        return copyDataTemplate(rowData, 'title')
    }

    const displayIdaCode = function (rowData) {
        return copyDataTemplate(rowData, 'idaCode')
    }

    const episodeColumnDefinitions = [
        { title: 'Ep.Code', mapsToField: 'sapCode', template: displayEpisodeCode, filter: true, filterMatchMode: FilterMatchMode.STARTS_WITH, sortable: true, editable: false },
        { title: 'Ep.Title', mapsToField: 'title', template: displayEpisodeTitle, filterMatchMode: FilterMatchMode.STARTS_WITH, filter: true, sortable: true, editable: true },
        { title: 'Ep.No', mapsToField: 'number', filter: true, filterMatchMode: FilterMatchMode.EQUALS, sortable: true, editable: true },
        { title: 'Series No.', mapsToField: 'series', filter: true, filterMatchMode: FilterMatchMode.EQUALS, sortable: true, editable: true },
        { title: 'Duration', mapsToField: 'durationMinutes', filter: false, filterMatchMode: FilterMatchMode.EQUALS, sortable: false, editable: true },
        { title: 'Year Produced', mapsToField: 'yearOfProduction', filter: true, filterMatchMode: FilterMatchMode.EQUALS, sortable: true, editable: true },
        { title: 'IDA No', mapsToField: 'idaCode', template: displayIdaCode, filter: true, filterMatchMode: FilterMatchMode.EQUALS, sortable: true, editable: true },
        { actions: true, template: displayActions }
    ]

    return (
        <div id={props.id + '_episodeList'}>
            <Toast ref={toast} />
            {showDataUpdate && (
                <DataListUpdate
                    id={props.id + '_DataListUpdate_audioVisual_Episode'}
                    key={dataUpdateKey}
                    setStatusAction={setEpisodeListUpdateStatusAction}
                    requestSelector={selectEpisodeListUpdateRequest}
                    setAggregateAction={setProgrammeOriginalAggregateVersionAction}
                    onComplete={handleUpdateComplete}
                />
            )}
            {episodeData.length > 0 && (
                <StaticCommonGrid
                    ref={gridRef}   
                    id={props.id + '_episodeList_commonGrid'}
                    dataKey="id"
                    keyColumnName="Ep.Code"
                    data={episodeData}
                    originalData={episodeDataOriginal}
                    totalCount={originalEpisodesCount}
                    emptyMessage="Programme has no Episodes"
                    columnDefinitions={episodeColumnDefinitions}
                    showPaginator={true}
                    allowRowEdits={true}
                    header={header}
                    selectionMode="multiple"
                    selectionStyle="checkbox"
                    scrollHeight="650px"
                    rowPerPage={10}
                    validateData={validateData}
                    onSave={handleRowEditSave}
                    startEditing={episodeListstartEditing}
                    stopEditing={episodeListstopEditing}  
                    excelDownloadPath={excelDownloadPath}
                    excelDownloadName={excelDownloadName}
                    setFilterAction={setAudiovisualFilterAction}
                    setSortAction={setAudiovisualSortAction}                  
                   
                />
            )}
        </div>
    )
}