import React , { forwardRef, use, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setBreadCrumbStack } from 'components/Header/SecondaryNavigation/store/actions'
import { AudioVisualContent } from './AudioVisualContent';
import { AudioVisualLandingFooter } from './AudioVisualLandingFooter'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { Toast } from 'primereact/toast'
import { useSelector } from 'react-redux'
import { selectEpisodeListResultPresentation, selectAudiovisualDraft } from 'components/Body/data-entry/AudioVisualLanding/store/selectors'

export const AudioVisualLanding = forwardRef((props, ref) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const setBreadCrumb = (stack) => dispatch(setBreadCrumbStack(stack))
    const cancelConfirmRef = useRef(null)
    const toast = useRef(null)
    const episodeList = useSelector(selectEpisodeListResultPresentation)
    const episodeListDraft = useSelector(selectAudiovisualDraft)

    const breadcrumbItem = {
        label: 'AV & Radio',
        path: location.pathname
    }

    setBreadCrumb([breadcrumbItem])   

    return (
        <div className='application-content d-flex flex-column flex-grow-1' >
            <div className='container-fluid d-flex flex-column flex-grow-1'>
                <div className='row app-content d-flex flex-grow-1'>                     
                    <ConfirmPopup />
                    <Toast ref={toast} className="toast-right" />
                    <AudioVisualContent id={props.id} episodeListDraft={episodeListDraft} />                   
                    <AudioVisualLandingFooter cancelConfirmRef={cancelConfirmRef} toast={toast} episodeList={episodeListDraft} />                   
                </div>
            </div>
        </div>
    )
})